import { Box, Button, HStack, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { ContextScope } from "../../Context/ContextProvider";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { setSelectedTask } from "../slices/selectedTaskSlice";
import { setModalMode } from "../slices/modalModeSlice";
import { setIsUpdateChatModalOpen } from "../slices/isUpdateChatModalOpenSlice";
import DoneTaskComponent from "../task/DoneTaskComponent";
import TeamList from "../task/TeamList";
import { useEffect, useState } from "react";
import ChatAttachments from "../attachments/ChatAttachments";
import alarm from "../../images/alarm.png";
import alarmon from "../../images/frontalarmon.png";
import { setIsAlarmModalOpen } from "../slices/isAlarmModalOpenSlice";
import { setSelectedAlarm } from "../slices/selectedAlarmSlice";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { setIsEventModalOpen } from "../slices/isEventModalOpenSlice";
import { format } from "date-fns";

const DrawerSubtask = ({ s }) => {
  const [daysLeftColor, setDaysLeftColor] = useState("gray.800");
  const [daysLeftBG, setDaysLeftBG] = useState("gray.300");
  const dispatch = useDispatch();
  const [showDescription, setShowDescription] = useState(true);
  const { selectedChat, setSelectedChat } = ContextScope();
  const handleAlarm = () => {
    dispatch(setIsAlarmModalOpen(true));
    dispatch(setSelectedAlarm(s.alarm));
  };
  const handleEvent = () => {
    dispatch(setIsEventModalOpen(true));
    // dispatch(setSelectedAlarm(chat.alarm));
  };
  const timeLeft = Math.round(
    (new Date(s.dateTo) - Date.now()) / (1000 * 60 * 60 * 24)
  );
  useEffect(() => {
    if (timeLeft < 10 && timeLeft > 0) {
      setDaysLeftBG("#ff9326");
      setDaysLeftColor("orange");
    } else if (timeLeft <= 0) {
      setDaysLeftBG("red");
      setDaysLeftColor("red");
    } else {
      setDaysLeftBG("gray.300");
      setDaysLeftColor("gray.800");
    }
  }, [s]);
  return (
    <Box w={"95%"} p={0} align={"left"}>
      <HStack
        onClick={() => {
          setSelectedChat(s);
        }}
        w={"100%"}
        paddingBottom={2}
        align={"left"}
        className="subtaskwraper"
        bg={s._id === selectedChat._id ? "#ffffff" : "#fafafa"}
        p={0}
        borderRadius={5}
        borderStyle={"solid"}
        borderWidth={2}
        borderColor={s._id === selectedChat._id ? "blue.200" : "gray.100"}
      >
        <VStack
          p={"5px 5px 5px 5px"}
          // borderBottom={"1px dashed #eaeaea"}
          w={"100%"}
          onClick={() => {
            dispatch(setSelectedTask(s));
            setSelectedChat(s);
          }}
          color={s._id === selectedChat._id ? "#555555" : "#afafaf"}
        >
          <HStack
            w={"100%"}
            align={"left"}
            opacity={s._id === selectedChat._id ? "1" : "0.2"}
          >
            <VStack
              w={"100%"}
              alignItems={"left"}
              alignSelf={"left"}
              textAlign={"left"}
              alignContent={"left"}
              align={"left"}
            >
              <Box
                align={"left"}
                className="hand small"
                onClick={() => {
                  dispatch(setModalMode("date"));
                }}
                alignItems={"left"}
                alignSelf={"left"}
                textAlign={"left"}
                alignContent={"left"}
                minWidth={"50px"}
                borderRadius={6}
                color={daysLeftColor}
                borderWidth={"0px"}
                borderColor={daysLeftBG}
                p={"2px 5px 1px 3px"}
              >
                {s.done === false ? (
                  <>{`${timeLeft} dni`}</>
                ) : (
                  <Box p={"0px 4px 0px 4px"}>
                    <FontAwesomeIcon color="#9fba3c" icon={faCheck} />
                  </Box>
                )}
              </Box>
              <HStack w={"100%"} align={"left"} borderRadius={3}>
                <DoneTaskComponent mode="task" chat={s} />
                <Button
                  m={"0px 5px"}
                  size={"xs"}
                  bg={"transparent"}
                  onClick={() => {
                    setSelectedChat(s);
                    dispatch(setModalMode("edit"));
                    dispatch(setIsUpdateChatModalOpen(true));
                  }}
                >
                  <FontAwesomeIcon size={"lg"} color="#aaaaaa" icon={faGear} />
                </Button>
                <ChatAttachments
                  setSelectedPrivateChat={s}
                  setSelectedChat={s}
                  rawChat={s}
                />
                <TeamList team={s.users} />
                <Button
                  isDisabled={true}
                  width={"40px"}
                  onClick={handleAlarm}
                  size={"xs"}
                >
                  {s?.alarm?.length > 0 ? (
                    <img alt="set alarm" src={alarmon} />
                  ) : (
                    <img
                      className="alarmimg"
                      width={"10px"}
                      height={"10px"}
                      src={alarm}
                      alt="set alarm"
                    />
                  )}
                </Button>
                <Button
                  onClick={handleEvent}
                  isDisabled={true}
                  title="historia"
                  color="blue.300"
                  width={"40px"}
                  size={"xs"}
                >
                  <FontAwesomeIcon size="md" icon={faCalendarDays} />
                </Button>
              </HStack>
            </VStack>
          </HStack>

          <Box className="" w={"100%"}>
            <p w={"100%"} className="small">
              {s.chatName}
            </p>
          </Box>
          {showDescription && s?.description && (
            <Box borderRadius={6} bg={"white"} w={"100%"} p={2}>
              <p
                dangerouslySetInnerHTML={{
                  __html: s?.description,
                }}
              ></p>
            </Box>
          )}
          {s?.description && (
            <Box align={"left"} w={"100%"}>
              <Button
                align={"left"}
                h={"35px"}
                color={"white"}
                bg={s._id === selectedChat._id ? "blue.200" : "gray.100"}
                onClick={() => {
                  setShowDescription(!showDescription);
                }}
                sixe={"xs"}
                p={"0px 15px 0px 15px"}
                rightIcon={
                  showDescription ? (
                    <FontAwesomeIcon size={"xs"} icon={faAngleUp} />
                  ) : (
                    <FontAwesomeIcon size={"xs"} icon={faAngleDown} />
                  )
                }
              >
                <p className="small">{showDescription ? "mniej" : "więcej"}</p>
              </Button>
            </Box>
          )}
        </VStack>
        {/* <Box
          p={"0px 2px 0px 2px"}
          alignContent={"center"}
          align={"center"}
          alignItems={"center"}
          alignSelf={"center"}
          className="leftbar"
          bg={s._id === selectedChat._id ? "#90cdf4" : "#eeeeee"}
        >
          <FontAwesomeIcon size={"xs"} color="#ffffff" icon={faChevronRight} />
        </Box> */}
      </HStack>
    </Box>
  );
};

export default DrawerSubtask;
