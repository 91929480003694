import { Box, Container, HStack, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Subtask from "./Subtask";

const Subtasks = ({ subtasks, chatDone }) => {
  const [doneSubtasks, setDoneSubtasks] = useState([]);
  const [todoSubtasks, setTodoSubtasks] = useState([]);
  useEffect(() => {
    const allDoneSubtasks = [];
    const allTodoSubtasks = [];
    subtasks.map((s) => {
      if (s.done === true)
        allDoneSubtasks.push(
          <Subtask color={"#d9e3a3"} chatDone={chatDone} subtask={s} />
        );
      else
        allTodoSubtasks.push(
          <Subtask color={"blue.100"} chatDone={chatDone} subtask={s} />
        );
    });
    // console.log("#################################");
    // console.log(allSubtasks);
    // console.log("#################################");
    setDoneSubtasks(allDoneSubtasks);
    setTodoSubtasks(allTodoSubtasks);
  }, [subtasks]);
  return (
    <HStack
      m={"0% 5%"}
      p={"10px 0px"}
      borderTop={"1px dashed #aaa"}
      align="start"
      justify="center"
    >
      <Box
        w={"50%"}
        bg={"#fafafa"}
        p={5}
        m={"10px 0"}
        borderRadius={5}
        spacing={4}
      >
        <VStack align="start" justify="flex-start" color="blue.300">
          <Box>
            <p className={"subtasks-label"}>W OPRACOWANIU</p>
          </Box>
          <Box w={"100%"}>{todoSubtasks}</Box>
        </VStack>
      </Box>
      <Box
        w={"50%"}
        bg={"#fafafa"}
        p={5}
        m={"10px 0"}
        borderRadius={5}
        spacing={4}
      >
        <VStack align="start" justify="flex-start" color="#acbd60">
          <p className={"subtasks-label"}>ZREALIZOWANE</p>
          <Box w={"100%"}>{doneSubtasks}</Box>
        </VStack>
      </Box>
    </HStack>
  );
};

export default Subtasks;
