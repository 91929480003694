// components/RejList.js
import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { useSelector } from "react-redux";
import { Box, Button, HStack, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const LiveVehiclesModule = ({ onVehicleClick }) => {
  const [activeUsers, setActiveUsers] = useState({});
  const user = useSelector((state) => state.userInfo.userInfo);

  useEffect(() => {
    const socket = io("https://notatka-backend.onrender.com", {
      withCredentials: true,
    });

    // Dołącz do pokoju trackingu
    socket.emit("join tracking room", "tracking");

    // Nasłuchuj aktualnych pozycji
    socket.on("new_position", (position) => { 
      if (!position) return;

      setActiveUsers((prev) => ({
        ...prev,
        [position.id]: {
          rej: position.rej,
          timestamp: position.timestamp,
          latitude: position.latitude, longitude: position.longitude
        },
      }));
    });

    // Nasłuchuj zakończenia trasy
    socket.on("route_saved", (route) => {
      setActiveUsers((prev) => {
        const updated = { ...prev };
        delete updated[route.id];
        return updated;
      });
    });

  socket.on('position_removed', ({ id }) => {
    setActiveUsers(prev => {
      const updated = { ...prev };
      delete updated[id];
      return updated;
    });
  });

    // Pobierz początkową listę aktywnych użytkowników
    const fetchInitialPositions = async () => {
      try {
        const response = await axios.get(`${mainPath}/api/route/live`);
        const positionsMap = response.data.reduce((acc, pos) => {
          acc[pos.id] = { rej: pos.rej, timestamp: pos.timestamp, latitude: pos.latitude, longitude: pos.longitude };
          return acc;
        }, {});
        setActiveUsers(positionsMap);
      } catch (error) {
        console.error("Błąd pobierania pozycji:", error);
      }
    };
    fetchInitialPositions();

    return () => {
      socket.disconnect();
    };
  }, [user]); 
  // Filtruj tylko aktywne pozycje (np. z ostatnich 5 minut)
  const getActiveEntries = () => { 
    const now = new Date();
    return Object.entries(activeUsers).filter(([_, userData]) => {
      return now - new Date(userData.timestamp) < 300000; // 5 minut
    });
  };

  return (
    <div className="rej-list-container">
      <h3>Aktualnie udostępniający pozycję:</h3>
      <ul className="rej-list">
        {getActiveEntries().map(([id, userData]) => (
          <li 
            key={id} 
            className="rej-item"
            onClick={() => { 
              onVehicleClick({
              latitude: userData.latitude, // Dodaj te pola do danych
              longitude: userData.longitude,
              rej: userData.rej
            });
            }}
            style={{ cursor: 'pointer' }}
          >
            <Box borderBottom={"1px solid #eee"} p={5}>
              <HStack>
                <Box>
                  <Button>
                    <FontAwesomeIcon
                      className="hand"
                      color="gray"
                      icon={faMagnifyingGlass}
                    />
                  </Button>
                </Box>
                <Box className={"small"} p={0} align={"start"}>
                        <p p={0}  className="gray">nr. rejestracyjny:</p>
                        <p p={0}  className="rej-number"><b>{userData.rej}</b></p>
                        <p p={0}  className="gray">aktualizacja:</p>
                        <p p={0}  className="rej-time">
                    {new Date(userData.timestamp).toLocaleTimeString()}
                  </p>
                </Box>
              </HStack>
            </Box>
          </li>
        ))}
        {getActiveEntries().length === 0 && (
          <li className="rej-item empty">Brak aktywnych użytkowników</li>
        )}
      </ul>
    </div>
  );
};

export default LiveVehiclesModule;
