import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  HStack,
  Button,
} from "@chakra-ui/react";
import { mainPath } from "../../config/AxiosConfig";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFetchAgain } from "../slices/fetchAgainSlice";
const AddTagComponent = ({ user }) => {
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [nazwa, setNazwa] = useState("");
  const addTag = async () => {
    setLoading(true);
    // console.log(nazwa);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(`${mainPath}/api/tags/add`, { name: nazwa }, config)
        .then((res) => {
          setNazwa("");
          dispatch(setFetchAgain(!fetchAgain));
          setLoading(false);
        });
    } catch (error) {
      // console.log(error.data);
    }
  };
  const setName = (e) => {
    setNazwa(e.target.value);
  };
  const handleClick = () => {
    if (nazwa.length > 0) addTag();
  };
  return (
    <HStack>
      <FormControl marginTop={"20px"} id="password" isRequired>
        <InputGroup size="md">
          <Input placeholder="wprowadź tag" onChange={setName} />
          <InputRightElement width="4,5rem">
            <Button
              isLoading={loading}
              bgColor="transparent"
              h="1,75rem"
              size="sm"
              p={3}
              value={nazwa}
              onClick={handleClick}
            >
              dodaj
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </HStack>
  );
};
export default AddTagComponent;
