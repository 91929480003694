import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Input,
  HStack,
  InputGroup,
  InputRightElement,
  Progress,
  VStack,
  Spinner,
  useDisclosure,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";

import { mainPath } from "../../config/AxiosConfig";
import axios from "axios";
import AlertsRow from "../../components/alerts/AlertsRow";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
const AlertsList = ({ setAlert }) => {
  const [alerts, setAlerts] = useState([]);
  const [filteredAlerts, setFilteredAlerts] = useState([]);
  const [addressValue, setAddressValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(<></>);
  const searchData = (e) => {
    if (e.target.value.length >= 0) {
      const searchWord = e.target.value;
      setAddressValue(searchWord);
      // dispatch(setSearchObject({ what: "name", value: searchWord }));
      const newFilter = alerts?.filter((d) => {
        const fullAddress = `${d.street} ${d.number}`;
        return fullAddress.toLowerCase().includes(searchWord.toLowerCase());
      });
      setFilteredAlerts(newFilter);
    } else {
      // // // // console.log(`e.target.value2: ${e.target.value}`);
      setFilteredAlerts([]);
    }
  };

  const clearAddressInput = () => {
    setAddressValue("");
    setFilteredAlerts(alerts);
  };
  const getAlarms = async () => {
    setLoading(true);
    console.log("getAlarms");
    try {
      const config = {
        headers: {
          // Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .get(`${mainPath}/api/alert/all`, config)
        .then((res) => {
          setAlerts(res.data.alerts);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  };
  useEffect(() => {
    getAlarms();
  }, []);
  useEffect(() => {
    if (alerts) {
      setContent(
        alerts?.map((a) => <AlertsRow alert={a} setAlert={setAlert} />)
      );
    }
  }, [alerts]);
  useEffect(() => {
    if (alerts.length > 0) {
      setContent(
        filteredAlerts?.map((a) => <AlertsRow alert={a} setAlert={setAlert} />)
      );
    }
  }, [filteredAlerts]);
  return (
    <Box
      className="mainbox"
      justifyContent="space-between"
      w="100%"
      h="91.5vh"
      p="10px"
    >
      {loading ? (
        <Progress height={"2px"} w={"100%"} size="xs" isIndeterminate />
      ) : (
        <Box
          p={0}
          w={"100%"}
          borderRadius="10px"
          className="tableBox"
          border={"1px solid #eee"}
          maxW={"98vw"}
          maxH={"80vh"}
          overflowX={"auto"}
          overflowY={"auto"}
        >
          <Table w="100%" h="100%">
            <Thead className="stickyHeader">
              <Tr bg={"white"}>
                <Th></Th>
                <Th>
                  <p>adres:</p>
                  <p>
                    <InputGroup>
                      <Input
                        name="addressInput"
                        value={addressValue}
                        onFocus={() => {
                          clearAddressInput();
                        }}
                        onChange={searchData}
                        borderRadius="full"
                        placeholder="szukaj adresu..."
                        size="sm"
                      />
                      <InputRightElement padding="0px 5px 7px 5px">
                        {/* {searchObject.what !== "name" ? (
                          <FontAwesomeIcon
                            color="gray"
                            icon={faMagnifyingGlass}
                          />
                        ) : ( */}
                        <FontAwesomeIcon
                          className="hand"
                          onClick={() => {
                            clearAddressInput();
                            setFilteredAlerts(alerts);
                            // dispatch(setSearchObject({}));
                          }}
                          color="gray"
                          icon={faXmark}
                        />
                        {/* )} */}
                      </InputRightElement>
                    </InputGroup>
                  </p>
                </Th>
                <Th>rodzaj alertu</Th>
                <Th>powiązana trasa</Th>
                <Th>data dodania</Th>
                <Th>autor</Th>
              </Tr>
            </Thead>
            <Tbody
              w="100%"
              align={"center"}
              alignContent={"center"}
              alignItems={"center"}
              alignSelf={"center"}
              justifyContent={"center"}
            >
              {content}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default AlertsList;
