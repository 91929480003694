import { Button } from "@chakra-ui/react";

const AlertDetails = ({ alert, setAlert }) => {
  return (
    <>
      {alert._id}
      <Button
        onClick={() => {
          setAlert(null);
        }}
      >
        x
      </Button>
    </>
  );
};

export default AlertDetails;
