import { Button } from "@chakra-ui/react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { mainPath } from "../../config/AxiosConfig";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setFetchAgain } from "../slices/fetchAgainSlice";

const DelRouteComponent = ({ rid }) => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.userInfo.userInfo);
  const dispatch = useDispatch();
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);

  useEffect(() => {
    setLoading(false);
  }, [fetchAgain]);
  const delRoute = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = axios
        .post(
          `${mainPath}/api/route/del/${rid}`,
          {
            rid: rid,
          },
          config
        )
        .then((res) => {
          dispatch(setFetchAgain(!fetchAgain));
        });
    } catch (error) {
      console.error("Błąd pobierania trasy:", error);
    }
  };

  return (
    <Button
      color={"white"}
      isLoading={loading}
      size={"sm"}
      bg={"red.300"}
      onClick={() => {
        if (window.confirm("Czy na pewno chcesz usunąć trasę?")) {
          delRoute();
        }
      }}
    >
      <FontAwesomeIcon size="bg" color="white" icon={faXmark} />
    </Button>
  );
};

export default DelRouteComponent;
