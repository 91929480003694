import { ContextScope } from "../../Context/ContextProvider";
import {
  Button,
  FormControl,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverCloseButton,
  PopoverTrigger,
  useToast,
  Spinner,
  PopoverHeader,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { useState } from "react";
import UserListItem from "../UserAvatar/UserListItem";
import { useSelector } from "react-redux";

const SearchUser = ({ setter, title }) => {
  let popHeader = <></>;
  const user = useSelector((state) => state.userInfo.userInfo);
  const { chats, setChats, selectedChat } = ContextScope();
  const toast = useToast();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [background, setBackground] = useState("#fafafa");
  const [overflow, setOverflow] = useState("hidden");
  const [border, setBorder] = useState("1px");
  const [inputValue, setInputValue] = useState("");
  const [path, setPath] = useState("");
  const modalMode = useSelector((state) => state.modalMode.modalMode);
  // console.log(modalMode);
  const selectedTask = useSelector((state) => state.selectedTask.selectedTask);
  const handleSearch = async (query) => {
    if (query !== "") {
      setLoading(true);
      popHeader = <PopoverCloseButton />;
      setBackground("#fafafa");
      setOverflow("hidden");
      setBorder("1px");
    }
    if (query.length >= 0) {
      setInputValue(query);
      setOverflow("scroll");
      setSearch(query);
      if (!query) {
        return;
      }
      try {
        let path = `${mainPath}/api/user?search=${search}`;
        if (modalMode === "edit" || modalMode === "new") {
          path = `${mainPath}/api/user?search=${search}`;
        } else if (modalMode === "sub") {
          
          path = `${mainPath}/api/user?search=${search}`;
        //  path = `${mainPath}/api/${selectedChat._id}/user?search=${search}`;
        }
        // console.log(path);
        setLoading(true);
        const config = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        };
        const { data } = await axios.get(path, config);
        setLoading(false);
        setSearchResult(data);
      } catch (error) {
        toast({
          title: `to err is human`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-left",
        });
      }
    } else {
      setOverflow("hidden");
    }
  };
  const handleSetter = (user) => {
    setInputValue("");
    setSearchResult([]);
    setter(user);
    setBorder("1px");
  };

  return (
    <Popover
      size={"xs"}
      isOpen={open}
      closeOnBlur={true}
      isLazy
      lazyBehavior="keepMounted"
    >
      <PopoverTrigger>
        <FormControl>
          <Input
            marginBottom="10px"
            marginTop="0px"
            value={inputValue}
            placeholder={title}
            onChange={(e) => handleSearch(e.target.value)}
            onFocus={async (e) => {
              setOpen(true);
              if (e.target.value !== "") {
                setLoading(true);
                popHeader = <PopoverCloseButton />;
                setBackground("#fafafa");
                setOverflow("hidden");
                setBorder("1px");
              }
            }}
            onBlur={(e) => {
              setLoading(false);
              // popHeader = <></>;
              // setBackground("transparent");
              // setBorder("0px");
              if (
                e.relatedTarget === null ||
                !e.relatedTarget.id.includes("popover-content")
              ) {
                e.target.focus();
                // setBorder("0px");
                // setOverflow("hidden");
                // setSearchResult([]);
              } else {
              }
            }}
          />
        </FormControl>
      </PopoverTrigger>
      <PopoverContent
        background={background}
        borderColor="gray.200"
        boxShadow="none"
        borderWidth={border}
        borderRadius={"10px"}
      >
        <PopoverHeader>
          <HStack height="20px">
            <p height="25px">{""}</p>
            <PopoverCloseButton onClick={() => setOpen(false)} />
          </HStack>
        </PopoverHeader>
        <PopoverBody maxHeight="200px" overflowY={overflow}>
          {loading ? (
            <div width="100%" align="center">
              <Spinner alignSelf={"center"} alignContent={"center"} />
            </div>
          ) : (
            searchResult?.slice(0, 4).map((user) => (
              <UserListItem
                key={user._id}
                user={user}
                handleFunction={() =>
                  handleSetter({
                    userToAdd: user,
                    chatId: selectedTask?._id,
                  })
                }
              />
            ))
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default SearchUser;
