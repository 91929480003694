import { Box, Stack, Text } from "@chakra-ui/layout";
import {
  CircularProgress,
  FormControl,
  FormLabel,
  Input,
  HStack,
  InputGroup,
  InputRightElement,
  Progress,
  VStack,
  Spinner,
  useDisclosure,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/button";
import axios from "axios";
import { mainPath } from "../config/AxiosConfig";
import React, { useEffect, useState } from "react";
import { ContextScope } from "../Context/ContextProvider";
import { AddIcon } from "@chakra-ui/icons";
import ChatLoading from "./ChatLoading";
import GroupChatModal from "./miscellaneous/GroupChatModal";
import TagsPanel from "./tags/TagsPanel";
import TaskComponent from "./task/TaskComponent";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UpdateGroupChatModal from "./miscellaneous/UpdateGroupChatModal";
import { useDispatch, useSelector } from "react-redux";
import { setModalMode } from "./slices/modalModeSlice";
import { setSelectedTask } from "./slices/selectedTaskSlice";
import { useHistory } from "react-router-dom";
import { setFetchAgain } from "./slices/fetchAgainSlice";
import { setShowDone } from "./slices/showDoneSlice";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { setSearchObject } from "./slices/searchObjectSlice";
import { set } from "date-fns";
import AlarmModal from "./alarm/AlarmModal";
import { setIsGroupChatModalOpen } from "./slices/isGroupChatModalOpenSlice";
import EventModal from "./events/EventsModal";
import ShowDoneComponent from "./ShowDoneComponent";
import AttachmentsModal from "./attachments/AttachmentsModal";

const MyChats = () => {
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const selectedTask = useSelector((state) => state.selectedTask.selectedTask);
  const [loggedUser, setLoggedUser] = useState();
  const [filteredChats, setFilteredChats] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);
  const [timeLoading, setTimeLoading] = useState(false);
  const [superLoading, setSuperLoading] = useState(false);
  const [lpLoading, setLpLoading] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [superValue, setSuperValue] = useState("");
  const { alert, setAlert, selectedChat, setSelectedChat, chats, setChats } =
    ContextScope();
  const dispatch = useDispatch();
  const searchObject = useSelector((state) => state.searchObject.searchObject);
  const selectedTags = useSelector((state) => state.selectedTags.selectedTags);
  const searchData = (e) => {
    if (e.target.value.length >= 0) {
      const searchWord = e.target.value;
      setNameValue(searchWord);
      dispatch(setSearchObject({ what: "name", value: searchWord }));
      const newFilter = chats?.filter((d) => {
        return d.chatName.toLowerCase().includes(searchWord.toLowerCase());
      });
      setFilteredChats(newFilter);
    } else {
      // // // // console.log(`e.target.value2: ${e.target.value}`);
      setFilteredChats([]);
    }
  };
  const searchSuper = (e) => {
    if (e.target.value.length >= 0) {
      const searchWord = e.target.value;
      setSuperValue(searchWord);
      dispatch(setSearchObject({ what: "super", value: searchWord }));
      const newFilter = chats?.filter((d) => {
        return d.groupSuper.name
          .toLowerCase()
          .includes(searchWord.toLowerCase());
      });
      setFilteredChats(newFilter);
    } else {
      // // // // console.log(`e.target.value2: ${e.target.value}`);
      setFilteredChats([]);
    }
  };
  const searchTag = (tags) => {
    const tagFilteredChats = [];
    tags?.map((tag) => {
      const searchWord = tag._id;
      const newFilter = chats?.filter((chat) => {
        chat.tags?.map((d) => {
          // console.log(d);
          // console.log(searchWord);
          if (d && d._id === searchWord && !tagFilteredChats.includes(chat))
            tagFilteredChats.push(chat);
        });
      });
    });
    // dispatch(setSearchObject({ what: "super", value: searchWord }));
    if (tagFilteredChats.length > 0) {
      setFilteredChats(tagFilteredChats);
    } else {
      setFilteredChats([]);
    }
  };
  const user = useSelector((state) => state.userInfo.userInfo);
  const toast = useToast();
  let { sort, asc } = useParams();
  useEffect(() => {
    if (sort === undefined) {
      sort = "0";
      asc = "0";
    }
  }, [sort]);
  const fetchChats = async () => {
    if (sort === undefined) {
      sort = "0";
      asc = "0";
    }
    setFetchLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .get(
          // `${mainPath}/api/chat/sort/${sort}/${asc}`,
          `${mainPath}/api/chat/chats/sort/${sort}/${asc}`,
          config
        )
        .then((response) => {
          setFetchLoading(false);
          setSuperLoading(false);
          setNameLoading(false);
          setTimeLoading(false);
          setLpLoading(false);
          if (searchObject.what === "name") {
            setNameValue(searchObject.value);
            const newFilter = response.data?.filter((d) => {
              return d.chatName
                .toLowerCase()
                .includes(searchObject.value.toLowerCase());
            });
            setChats(newFilter);
          } else if (searchObject.what === "super") {
            setSuperValue(searchObject.value);
            const newFilter = response.data?.filter((d) => {
              return d.groupSuper.name
                .toLowerCase()
                .includes(searchObject.value.toLowerCase());
            });
            setChats(newFilter);
          } else {
            setChats(response.data);
          }
        });
    } catch (error) {
      // toast({
      //   title: error.message,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      //   position: "bottom-left",
      // });
    }
  };
  useEffect(() => {
    // // // console.log(`user`);
    // // // console.log(user);
    // // // console.log(`user`);
    setFilteredChats(chats);
    if (selectedTags.length > 0) searchTag(selectedTags);
  }, [chats]);
  useEffect(() => {
    // // console.log(selectedTags);
    if (selectedTags.length > 0) {
      searchTag(selectedTags);
    } else {
      dispatch(setFetchAgain(!fetchAgain));
    }
  }, [selectedTags]);
  useEffect(() => {
    // // console.log("##########");
    // // console.log(user);
    // // console.log("##########");
    if (user) {
      dispatch(setFetchAgain(!fetchAgain));
    }
    dispatch(setShowDone(user.showDone));
  }, [user]);

  useEffect(() => {
    // // // // console.log(`fetching...`);
    setLoggedUser(JSON.parse(localStorage.getItem("userInfo")));
    fetchChats();
  }, [fetchAgain]);

  useEffect(() => {
    if (searchObject.what == null) {
      fetchChats();
    }
  }, [searchObject]);

  const setMode = () => {
    dispatch(setIsGroupChatModalOpen(true));
    setSelectedChat(null);
    dispatch(setSelectedTask({}));
    dispatch(setModalMode("new"));
  };
  const history = useHistory();
  const redirectPage = (where) => {
    setFilteredChats([]);
    history.push(`/${where}`);
    dispatch(setFetchAgain(!fetchAgain));
  };
  const clearNameInput = () => {
    setNameValue("");
  };
  const clearSuperInput = () => {
    setSuperValue("");
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <UpdateGroupChatModal />
      <EventModal selectedTask={selectedTask} user={user} />
      <AlarmModal />
      <VStack>
        <HStack w={"95vw"}>
          <Button
            onClick={setMode}
            borderRadius={"full"}
            bg={"blue.200"}
            color={"#ffffff"}
            size={"md"}
            display="flex"
            margin={"15px 20px 5px 10px"}
            fontSize={{ base: "12px" }}
            colorScheme="linkedin"
            leftIcon={<FontAwesomeIcon color="white" icon={faPlus} />}
          >
            Nowe zadanie
          </Button>
          <Box borderLeft={"1px dashed #eaeaea"}>
            <ShowDoneComponent user={user} />
          </Box>
          <TagsPanel user={user} />
        </HStack>
        <Box
          p={0}
          w={"100%"}
          borderRadius="10px"
          className="tableBox"
          border={"1px solid #eee"}
          maxW={"98vw"}
          maxH={"80vh"}
          overflowX={"auto"}
          overflowY={"auto"}
        >
          <HStack>
            <Box
              pb={3}
              px={3}
              fontSize={{ base: "28px", md: "30px" }}
              display="flex"
              w="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <GroupChatModal />
              <AttachmentsModal user={user} />
            </Box>
          </HStack>
          {/* {filteredChats.length > 0 ? ( */}
          <Table w="100%" h="100%">
            <Thead className="stickyHeader">
              <Th>etapy</Th>
              <Th minWidth={"300px"}>
                <VStack width="100%" justifyContent="space-between">
                  <HStack>
                    <Button
                      color={"#555555"}
                      bg={sort === "0" ? "blue.200" : "gray.200"}
                      p={"10px 15px"}
                      size={"xs"}
                      onClick={() => {
                        clearNameInput();
                        clearSuperInput();
                        setFilteredChats(chats);
                        dispatch(setSearchObject({}));
                        setLpLoading(true);
                        redirectPage("chats/0");
                      }}
                    >
                      {superLoading ? (
                        <Spinner size={"sm"} margin="5px" color="white" />
                      ) : (
                        "LP"
                      )}
                    </Button>
                    <Button
                      color={"#555555"}
                      bg={sort === "3" ? "blue.200" : "gray.200"}
                      p={"10px 15px"}
                      size={"xs"}
                      onClick={() => {
                        clearNameInput();
                        clearSuperInput();
                        setFilteredChats(chats);
                        dispatch(setSearchObject({}));
                        setNameLoading(true);
                        redirectPage("chats/3");
                      }}
                    >
                      {nameLoading ? (
                        <Spinner size={"sm"} margin="5px" color="white" />
                      ) : (
                        "NAZWA"
                      )}
                    </Button>
                  </HStack>
                  <InputGroup>
                    <Input
                      name="nameInput"
                      value={nameValue}
                      onFocus={() => {
                        clearSuperInput();
                      }}
                      onChange={searchData}
                      borderRadius="full"
                      placeholder="szukaj zadania..."
                      size="sm"
                    />
                    <InputRightElement padding="0px 5px 7px 5px">
                      {searchObject.what !== "name" ? (
                        <FontAwesomeIcon
                          color="gray"
                          icon={faMagnifyingGlass}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="hand"
                          onClick={() => {
                            clearNameInput();
                            setFilteredChats(chats);
                            dispatch(setSearchObject({}));
                          }}
                          color="gray"
                          icon={faXmark}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                </VStack>
              </Th>
              {/* <Th>
              {" "}
              pokaż ukończone{" "}
              <Switch
                colorScheme="green"
                onChange={switchHandler}
                id="email-alerts"
              />
            </Th> */}
              <Th>
                <VStack width="100%" justifyContent="space-between">
                  <HStack>
                    <span>ZESPÓŁ</span>
                    <Button
                      color={"#555555"}
                      bg={sort === "4" ? "blue.200" : "gray.200"}
                      p={"10px 15px"}
                      size={"xs"}
                      onClick={() => {
                        clearNameInput();
                        clearSuperInput();
                        setFilteredChats(chats);
                        dispatch(setSearchObject({}));
                        setSuperLoading(true);
                        redirectPage("chats/4");
                      }}
                    >
                      {superLoading ? (
                        <Spinner size={"sm"} margin="5px" color="white" />
                      ) : (
                        "KIEROWNIK ZADANIA"
                      )}
                    </Button>{" "}
                  </HStack>
                  <InputGroup>
                    <Input
                      value={superValue}
                      name="superInput"
                      onFocus={() => {
                        clearNameInput();
                      }}
                      onChange={searchSuper}
                      borderRadius="full"
                      placeholder="szukaj kierownika..."
                      size="sm"
                    />
                    <InputRightElement padding="0px 5px 7px 5px">
                      {searchObject.what !== "super" ? (
                        <FontAwesomeIcon
                          color="gray"
                          icon={faMagnifyingGlass}
                        />
                      ) : (
                        <FontAwesomeIcon
                          className="hand"
                          onClick={() => {
                            dispatch(dispatch(setSearchObject({})));
                            clearSuperInput();
                            setFilteredChats(chats);
                          }}
                          color="gray"
                          icon={faXmark}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                </VStack>
              </Th>
              <Th>
                <Button
                  color={"#555555"}
                  bg={sort === "6" ? "blue.200" : "gray.200"}
                  p={"10px 15px"}
                  size={"xs"}
                  onClick={() => {
                    clearNameInput();
                    clearSuperInput();
                    setFilteredChats(chats);
                    dispatch(setSearchObject({}));
                    setTimeLoading(true);
                    redirectPage("chats/6");
                  }}
                >
                  {timeLoading ? (
                    <Spinner size={"sm"} margin="5px" color="white" />
                  ) : (
                    "TERMIN REALIZACJI"
                  )}
                </Button>
              </Th>
              <Th> </Th>
            </Thead>
            <Tbody
              w="100%"
              align={"center"}
              alignContent={"center"}
              alignItems={"center"}
              alignSelf={"center"}
              justifyContent={"center"}
            >
              {filteredChats.length > 0 ? (
                <>
                  {filteredChats?.map((chat) => (
                    <TaskComponent
                      w="100%"
                      backgroundColor={"blue"}
                      chat={chat}
                      key={chat._id}
                      loggedUser={loggedUser}
                    />
                  ))}{" "}
                </>
              ) : (
                <>
                  {fetchLoading ? (
                    <>
                      <Tr>
                        <Td className="noBorder" colSpan={8}>
                          <HStack
                            p={"40px 0px 0px 0px"}
                            w={"100%"}
                            align={"center"}
                            alignContent={"center"}
                            alignItems={"center"}
                            alignSelf={"center"}
                            justifyContent={"center"}
                          >
                            <CircularProgress
                              isIndeterminate
                              color="blue.300"
                              marginBottom={"20px"}
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    </>
                  ) : (
                    <>
                      <Tr>
                        <Td className="noBorder small" colSpan={8}>
                          Nie znaleziono zadania...
                        </Td>
                      </Tr>
                    </>
                  )}
                </>
              )}
              <Tr>
                <Td className="noBorder" colSpan={8}></Td>
              </Tr>
            </Tbody>
          </Table>
          {/* ) : (
          <CircularProgress
            isIndeterminate
            color="blue.300"
            marginBottom={"20px"}
          />
        )} */}
        </Box>
      </VStack>
    </>
  );
};

export default MyChats;
