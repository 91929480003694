import { mainPath } from "../../config/AxiosConfig";
import axios from "axios";
import TagItem from "./TagItem";
import AddTagComponent from "./AddTagComponent";
import { Button } from "@chakra-ui/button";
import { useEffect, useState } from "react";
import {
  Container,
  HStack,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import ScrollableFeed from "react-scrollable-feed";

const TagsPanel = ({ user }) => {
  const [tags, setTags] = useState([]);
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addTag, setAddTag] = useState(false);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  useEffect(() => {
    getTags();
    setLoading(true);
  }, [user]);
  useEffect(() => {
    const allTags = tags.map((m) => <TagItem tag={m} />);
    setContent(allTags);
  }, [tags]);
  useEffect(() => {
    // console.log("fetchAgain");
    getTags();
  }, [fetchAgain]);

  const getTags = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .get(`${mainPath}/api/tags/getall`, config)
        .then((res) => {
          setTags(res.data);
          setLoading(false);
        });
    } catch (error) {
      // console.log(error.data);
    }
  };
  const handleClick = () => {
    setAddTag(!addTag);
  };
  return (
    <>
      <Popover>
        <PopoverTrigger>
          <Button color={"#555555"} p={"10px 15px"} size={"xs"}>
            #Tagi
          </Button>
        </PopoverTrigger>

        <PopoverContent>
          <PopoverArrow />
          <PopoverBody
            p={2}
            alignContent={"start"}
            align={"left"}
            className="tagpop"
          >
            <Button onClick={handleClick}>dodaj tag</Button>
            {addTag && <AddTagComponent user={user} />}
            <Container p={2}>{content}</Container>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default TagsPanel;
