import { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Polyline,
  Marker,
} from "@react-google-maps/api";
import { mainPath } from "../config/AxiosConfig";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AlertDetails from "../components/alerts/AlertDetails";

const Alertpage = ({ alert, setAlert }) => {
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY; 

  const containerStyle = {
    width: "100%",
    height: "100vh",
  };

  const MapComponent = () => {
    const [map, setMap] = useState(null);

    const onLoad = (map) => {
      setMap(map);
    };

    const onUnmount = () => {
      setMap(null);
    };

    return (
      <>
        {alert && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={alert?.alert && alert?.alert[0]}
            zoom={17}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {/* Rysowanie trasy */}
            <Polyline
              path={alert?.alert && alert?.alert}
              options={{
                strokeColor: "#FF0000",
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
          </GoogleMap>
        )}
      </>
    );
  };
  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div style={{ width: "35vw" }}>
        <AlertDetails alert={alert} setAlert={setAlert} />
      </div>
      <div style={{ width: "65vw" }}>
        <LoadScript googleMapsApiKey={API_KEY} libraries={["places"]}>
          <MapComponent />
        </LoadScript>
      </div>
    </div>
  );
};

export default Alertpage;
