import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Input,
  HStack,
  InputGroup,
  InputRightElement,
  Progress,
  VStack,
  Spinner,
  useDisclosure,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  Button,
  MenuIcon,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";

import { mainPath } from "../config/AxiosConfig";
import axios from "axios";
import RoutesRow from "../components/routes/RoutesRow";
import { useEffect, useState } from "react";
import RoutesList from "../components/routes/RoutesList";
import Routepage from "./Routepage";
import ProfileModal from "../components/miscellaneous/ProfileModal";
import { useSelector } from "react-redux";
import { ChevronDownIcon } from "@chakra-ui/icons";
import SingleRouteDetails from "./SingleRouteDetails";

const Routespage = () => {
  const user = useSelector((state) => state.userInfo.userInfo);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const [route, setRoute] = useState("");
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(false);
  const getRoutes = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          // Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .get(`${mainPath}/api/route/all`, config)
        .then((res) => {
          console.log(res);
          setRoutes(res.data.message);
          setLoading(false);
        });
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getRoutes();
  }, []);
  useEffect(() => {
    getRoutes();
  }, [fetchAgain]);

  return (
    <VStack
      w={"100%"}
      align={"right"}
      alignItems={"right"}
      alignSelf={"right"}
      alignContent={"right"}
      p={0}
      bg={"#fafafa"}
    >
      {!route && (
        <HStack
          className={"topPanel"}
          w={"100%"}
          align={"right"}
          alignItems={"right"}
          alignSelf={"right"}
          alignContent={"right"}
          p={3}
          bg={"#fafafa"}
        >
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              <Avatar
                size="sm"
                cursor="poiner"
                name={user?.name}
                src={user?.pic}
              ></Avatar>
            </MenuButton>
            <MenuList>
              <ProfileModal user={user}>
                <MenuItem>
                  <VStack alignItems={"left"} alignContent={"left"}>
                    <p align={"left"}>Mój profil:</p>
                    <p className="small gray">{user?.email}</p>
                  </VStack>
                </MenuItem>
              </ProfileModal>
              <MenuDivider />
            </MenuList>
          </Menu>
        </HStack>
      )}
      {route ? (
        <SingleRouteDetails
          start={route.start}
          rej={route.rej}
          end={route.stop}
          otherRoutes={routes}
          setRoute={setRoute}
          baseRoute={route.route}
          alerts={route.alerts}
        />
      ) : (
        <>
          {loading ? (
            <Progress height={"2px"} w={"100%"} size="xs" isIndeterminate />
          ) : (
            <Box border={"1px solid #eee"}></Box>
          )}
          <RoutesList routes={routes} setRoute={setRoute} />
        </>
      )}
    </VStack>
  );
};

export default Routespage;
