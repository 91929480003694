import { Box, Button, HStack, Td, Tr, VStack } from "@chakra-ui/react";
import calculateTotalDistance from "../../services/calculateDistance";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "@chakra-ui/layout";
import doubleBlue from "../../images/double-blue.png";
import bin from "../../images/bin.png";
import { icon } from "@fortawesome/fontawesome-svg-core";
import noBin from "../../images/no-bin.png";
import doubleGreen from "../../images/double-green.png";
import doubleRed from "../../images/double-red.png";
import doubleYellow from "../../images/double-yellow.png";
import doubleGray from "../../images/double-gray.png";
import noBio from "../../images/no-bio.png";
import flagYellow from "../../images/yellowflag.png";
import flagRed from "../../images/redflag.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import bin from "../../images/bin.png";

const AlertsRow = ({ alert, setAlert }) => {
  console.log(alert);
  const icon =
    alert.alertType == "no-can"
      ? noBin
      : alert.alertType == "double-blue"
      ? doubleBlue
      : alert.alertType == "double-green"
      ? doubleGreen
      : alert.alertType == "double-red"
      ? doubleRed
      : alert.alertType == "double-gray"
      ? doubleGray
      : alert.alertType == "double-yellow"
      ? doubleYellow
      : alert.alertType == "no-bio"
      ? noBio
      : alert.alertType == "double-red"
      ? doubleRed
      : alert.alertType == "flag-yellow"
      ? flagYellow
      : alert.alertType == "flag-red"
      ? flagRed
      : bin;
  const bgcolor =
    alert.alertType == "no-can"
      ? "#fafafa"
      : alert.alertType == "double-blue"
      ? "#0879B2"
      : alert.alertType == "double-green"
      ? "#6BA32F"
      : alert.alertType == "double-red"
      ? "#A34F17"
      : alert.alertType == "double-gray"
      ? "#3F3F3F"
      : alert.alertType == "double-yellow"
      ? "#FFBC00"
      : alert.alertType == "no-bio"
      ? "#fafafa"
      : alert.alertType == "double-red"
      ? "#A34F17"
      : alert.alertType == "flag-yellow"
      ? "#FFBC00"
      : alert.alertType == "flag-red"
      ? "red"
      : bin;
  const formatDate = format(alert.timestamp, "dd MMMM yyyy, HH:mm", {
    locale: pl,
  });

  return (
    <>
      <Tr className="alerts-row">
        <Td></Td>
        <Td align="left">
          <Container>
            <p className="small gray">ulica: </p>
            <p>
              <b>
                {alert?.street} {alert.number}
              </b>
            </p>
          </Container>
          {alert.city && (
            <Container marginTop={2}>
              <p className="small gray">miejscowość: </p>
              <p>{alert.city}</p>
            </Container>
          )}
          {/* <p className="gray">{alert._id}</p> */}
        </Td>
        <Td align="left">
          <Box
            sx={{
              width: 85,
              height: 85,
              background: bgcolor,
              borderRadius: "50%",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: 3,
            }}
          >
            <img src={icon} alt="Ikona" width="50" height="50" />
          </Box>
          {alert.alertType}
        </Td>
        <Td>
          {alert?.route?._id && (
            <Link
              to={`route/${alert?.route?._id}`}
              target="_blank"
              rel="noopener noreferer"
            >
              <Button size={"sm"} color={"white"} bg={"blue.200"}>
                <FontAwesomeIcon
                  size="bg"
                  color="white"
                  icon={faMagnifyingGlass}
                />
              </Button>
            </Link>
          )}
        </Td>
        <Td>
          <p>{formatDate}</p>
        </Td>
        <Td>
          <p>{alert.rej}</p>
        </Td>
        <Td>
          <Button size={"sm"} bg={"red.300"} onClick={() => {}}>
            <FontAwesomeIcon size="bg" color="white" icon={faXmark} />
          </Button>
        </Td>
      </Tr>
    </>
  );
};

export default AlertsRow;
