import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Progress,
  CircularProgress,
  Box,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { mainPath } from "../../config/AxiosConfig";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faMagnifyingGlass,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import calculateTotalDistance from "../../services/calculateDistance";

const RoutesModal = ({ getRoute }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [route, setRoute] = useState("");
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(<></>);

  const getRoutes = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          // Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .get(`${mainPath}/api/route/all`, config)
        .then((res) => {
          setRoutes(res.data.message);
          setLoading(false);
        });
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getRoutes();
  }, []);
  useEffect(() => {
    const newRoutes = routes?.map((m) => {
      const distance = calculateTotalDistance(m.route);
      return (
        <Box borderBottom={"1px solid #eee"}>
          <HStack p={"5px 0 5px 0"}>
            <Button
              bg={"blue.200"}
              marginRight={"15px"}
              onClick={() => {
                // console.log(m);
                getRoute({ crid: m._id });
              }}
            >
              <FontAwesomeIcon
                className="hand"
                color="white"
                icon={faMagnifyingGlass}
              />
            </Button>
            <Box paddingBottom={"7px"} align={"left"} className="small">
              <p>
                <span className="title">
                  <b>{m.rej}</b>
                </span>
              </p>
              <p>
                <span className="gray">data: </span>
                <span>{formatDate(m.start)}</span>
              </p>
              <p>
                <span className="gray">dystans: </span>
                <span>
                  <b>{distance}</b> m.
                </span>
              </p>
            </Box>
          </HStack>
        </Box>
      );
    });
    setContent(newRoutes);
  }, [routes]);

  const formatDate = (dateString) => {
    return format(new Date(dateString), "d MMMM yyyy, HH:mm", { locale: pl });
  };

  return (
    <>
      <Button marginLeft={3} onClick={onOpen}>
        Zmień trasę
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>wybierz trasę</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH={"500px"} style={{ overflowY: "auto" }}>
            {" "}
            {loading ? (
              <CircularProgress w={"15px"} isIndeterminate color="blue.200" />
            ) : (
              <>{content}</>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RoutesModal;
