function calculateTotalDistance(points) {
  const R = 6371e3; // Promień Ziemi w metrach

  let totalDistance = 0;

  for (let i = 0; i < points.length - 1; i++) {
    const p1 = points[i];
    const p2 = points[i + 1];

    // Konwersja stopni na radiany
    const lat1 = (p1.lat * Math.PI) / 180;
    const lon1 = (p1.lng * Math.PI) / 180;
    const lat2 = (p2.lat * Math.PI) / 180;
    const lon2 = (p2.lng * Math.PI) / 180;

    // Różnice współrzędnych
    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    // Formuła Haversine
    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    totalDistance += R * c;
  }

  return totalDistance.toFixed(2); // Zwróć wynik z dokładnością do 2 miejsc po przecinku
}

export default calculateTotalDistance;
