import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  Spinner,
  Text,
  Toast,
  VStack,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { mainPath } from "../config/AxiosConfig";
import React, { useEffect, useState } from "react";
import { getSender, getSenderFull } from "../config/ChatLogics";
import { ContextScope } from "../Context/ContextProvider";
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import ProfileModal from "./miscellaneous/ProfileModal";
import UpdateGroupChatModal from "./miscellaneous/UpdateGroupChatModal";
import ScrollableChat from "./ScrollableChat";
import io from "socket.io-client";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import SendFilesComponent from "./attachments/SendFilesComponent";
import { setSelectedFile } from "./slices/selectedFileSlice";

  const ENDPOINT = process.env.REACT_APP_BACKEND_URL;
var socket, selectedChatCompare;

const SinglePrivateChat = () => {
  const selectedFile = useSelector((state) => state.selectedFile.selectedFile);
  const toast = useToast();
  const dispatch = useDispatch();
  const selectedTask = useSelector((state) => state.selectedTask.selectedTask);
  const user = useSelector((state) => state.userInfo.userInfo);
  const { selectedPrivateChat, setSelectedPrivateChat } = ContextScope();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMessage, setnewMessage] = useState("");
  const [socketConnected, setSocketConnected] = useState(false);

  const fetMessages = async () => {
    if (!selectedPrivateChat) return;

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      };
      setLoading(true);
      const { data } = await axios.get(
        `${mainPath}/api/message/${selectedPrivateChat._id}`,
        config
      );
      setMessages(data);
      setLoading(false);
      socket.emit("join chat", selectedPrivateChat._id);
    } catch (error) {
      // toast({
      //   title: error.message,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      //   position: "bottom-left",
      // });
      setLoading(false);
    }
  };

  useEffect(() => {
    socket = io(ENDPOINT);
    socket.emit("setup", user);
    socket.on("connection", () => setSocketConnected(true));
  }, []);
  useEffect(() => {
    fetMessages();
    selectedChatCompare = selectedPrivateChat;
  }, [selectedPrivateChat]);

  useEffect(() => {
    setMessages([]);
  }, [selectedTask]);

  useEffect(() => {
    socket.on("message recieved", (newMessageRecieved) => {
      if (
        !selectedChatCompare || // if chat is not selected or doesn't match current chat
        selectedChatCompare._id !== newMessageRecieved.chat._id
      ) {
        // if (!notification.includes(newMessageRecieved)) {
        //   setNotification([newMessageRecieved, ...notification]);
        //   setFetchAgain(!fetchAgain);
        // }
      } else {
        setMessages([...messages, newMessageRecieved]);
      }
    });
  });

  const sendMessage = async (event) => {
    if (event.key === "Enter" && newMessage) {
      submitImage();
    }
  };

  const sendIt = async (file) => {
    // console.log(`sendIt`);
    // console.log(file);
    let content = "";
    if (newMessage) {
      content = newMessage;
    } else {
      content = file.uploadedImageName;
    }
    // console.log(`sendIt2`);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      setnewMessage("");
      const { data } = await axios.post(
        `${mainPath}/api/message`,
        {
          content: content,
          chatId: selectedPrivateChat._id,
          file: file,
        },
        config
      );
      // // // console.log(data);
      dispatch(setSelectedFile({}));

      socket?.emit("new message", data);
      setMessages([...messages, data]);
    } catch (error) {
      // toast({
      //   title: error.message,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      //   position: "bottom-left",
      // });
    }
  };

  const typingHandler = (e) => {
    setnewMessage(e.target.value);
  };

  const submitImage = async () => {
    if (selectedFile?.name) {
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        };
        const formData = new FormData();
        // console.log(`selectedFile`);
        // console.log(selectedFile);
        // console.log(`selectedFile`);
        formData.append("file", selectedFile.file);
        formData.append("name", selectedFile.name);
        const { data } = await axios
          .post(`${mainPath}/api/file/upload`, formData, config)
          .then((res) => {
            if (res.data.message === "success") {
              // console.log(res.data);
              sendIt(res.data);
            }
          });
      } catch (error) {}

      //==
    } else {
      // console.log(`submitImage2`);
      sendIt({});
    }
  };

  return (
    <>
      {selectedPrivateChat ? (
        <>
          <Text
            w={"100%"}
            p={3}
            borderRadius={10}
            bg={"#ffffff"}
            display="flex"
            justifyContent={{ base: "space-between" }}
            alignItems="center"
            marginBottom={2}
          >
            <IconButton
              display={{ base: "flex", md: "none" }}
              icon={<ArrowBackIcon />}
              onClick={() => setSelectedPrivateChat("")}
            />
            {!selectedPrivateChat.isGroupChat ? (
              <HStack>
                <p className="chatTitle">
                  {getSender(user, selectedPrivateChat.users)}
                </p>
              </HStack>
            ) : (
              <></>
            )}
          </Text>
          <Box
            display="flex"
            flexDir="column"
            justifyContent="flex-end"
            w="100%"
            h="100%"
            p={"0px 10px 0px 5px"}
            backgroundColor={"white"}
            borderRadius="lg"
            overflowY="hidden"
            bg={"#fafafa"}
          >
            {loading ? (
              <Box h={"100%"} padding="6">
                <SkeletonCircle size="10" />
                <SkeletonText
                  mt="4"
                  noOfLines={4}
                  spacing="4"
                  skeletonHeight="2"
                />
              </Box>
            ) : (
              <>
                {/* <div className="messages"> */}
                {/* {// console.log(messages)}  */}
                <ScrollableChat className="messages" messages={messages} />
                {/* </div> */}
              </>
            )}
          </Box>
          <FormControl onKeyDown={sendMessage} isRequired mt={3}>
            <InputGroup>
              <Input
                variant="filled"
                bg="#eeeeee"
                placeholder="Wpisz wiadomość..."
                onChange={typingHandler}
                value={newMessage}
              />

              <InputRightAddon border={"none"} bg={"gray.100"}>
                <SendFilesComponent user={user} cid={selectedPrivateChat._id} />
                {/* <Button marginLeft={1} size={"xs"} onClick={sendIt}> */}
                <Button marginLeft={1} size={"xs"} onClick={submitImage}>
                  <FontAwesomeIcon
                    className="hand"
                    color="#05cdff"
                    icon={faPaperPlane}
                  />
                </Button>
              </InputRightAddon>

              {/* <InputRightElement onClick={sendIt}>
                <FontAwesomeIcon
                  className="hand"
                  color="#05cdff"
                  icon={faPaperPlane}
                />
              </InputRightElement> */}
            </InputGroup>
          </FormControl>
        </>
      ) : (
        <Box>
          <Text>Wybierz osobę, do której chcesz napisać...</Text>
        </Box>
      )}
    </>
  );
};

export default SinglePrivateChat;
