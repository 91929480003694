import { createSlice } from "@reduxjs/toolkit";

export const selectedTagsSlice = createSlice({
  name: "selectedTags",
  initialState: {
    selectedTags: [],
  },

  reducers: {
    setSelectedTags: (state, action) => {
      state.selectedTags = action.payload;
    },

    addToSelectedTags: (state, action) => {
      return {
        ...state,
        selectedTags: [...state.selectedTags, action.payload],
      };
    },
    removeFromSelectedTags: (state, action) => {
      return {
        ...state,
        selectedTags: state.selectedTags.filter(
          (event) => event._id !== action.payload
        ),
      };
    },
  },
});

export const { setSelectedTags, addToSelectedTags, removeFromSelectedTags } =
  selectedTagsSlice.actions;
export default selectedTagsSlice.reducer;
