import {
  Box,
  Button,
  Collapse,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setIsAlarmModalOpen } from "../slices/isAlarmModalOpenSlice";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { mainPath } from "../../config/AxiosConfig";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import axios from "axios";
import SingleAlarmComponent from "./SingleAlarmComponent";
import { Textarea } from "@chakra-ui/react";

const AlarmsComponent = ({ chat }) => {
  const [content, setContent] = useState(<></>);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [alarmExists, setAlarmExists] = useState(false);
  const [hour, setHour] = useState(0);
  const [message, setMessage] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const user = useSelector((state) => state.userInfo.userInfo);
  const isAlarmModalOpen = useSelector(
    (state) => state.isAlarmModalOpen.isAlarmModalOpen
  );
  const toast = useToast();
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const setAlarm = async () => {
    setLoading(true);

    let date_time = new Date(endDate);

    let year = date_time.getFullYear();
    let month = ("0" + (date_time.getMonth() + 1)).slice(-2);
    let day = ("0" + date_time.getDate()).slice(-2);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const aid = null;
      const { data } = await axios
        .post(
          `${mainPath}/api/alarm/set/${aid}`,
          {
            cid: chat?._id,
            uid: user._id,
            day: day,
            month: month,
            message: message,
            year: year,
            hour: parseInt(hour),
          },
          config
        )
        .then((res) => {
          dispatch(setFetchAgain(!fetchAgain));
          setAlarmExists(true);
          setLoading(false);
        });
    } catch (error) {
      // console.log(error);
      toast({
        title: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setLoading(false);
    }
  };
  const getAlarms = async () => {
    console.log("getAlarms");
    setDelLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          `${mainPath}/api/alarm/get/${chat?._id}`,
          {
            cid: chat?._id,
            uid: user._id,
          },
          config
        )
        .then((res) => {
          // console.log(res);
          setContent(
            res.data?.map((alarm) => (
              <SingleAlarmComponent
                user={user}
                cid={chat?._id}
                alarm={alarm}
                key={alarm._id}
              />
            ))
          );
        });
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  const dispatch = useDispatch();
  const setOnMessageChange = (e) => {
    setMessage(e.target.value);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const closeModal = () => {
    dispatch(setIsAlarmModalOpen(false));
  };
  useEffect(() => {
    if (isAlarmModalOpen) {
      getAlarms();
      onOpen();
    } else onClose();
  }, [isAlarmModalOpen]);

  useEffect(() => {
    setMessage(`Przypomnienie: \n${chat?.chatName}`);
    getAlarms();
  }, [chat]);
  useEffect(() => {
    getAlarms();
  }, [fetchAgain]);

  return (
    <VStack>
      <HStack w={"100%"}>
        <HStack w={"60%"}>
          <FontAwesomeIcon color="#90cdf4" icon={faCalendarDays} />
          <Box
            w={"100%"}
            h={"40px"}
            alignContent={"center"}
            border={"1px solid #eaeaea"}
            borderRadius={5}
            p={1}
          >
            <DatePicker
              wrapperClassName="datepicker"
              alignItems={"center"}
              isDisabled={isAdmin && true}
              width="150px"
              textAlign={"center"}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Box>
        </HStack>
        <HStack w={"40%"} marginLeft={4}>
          <FontAwesomeIcon color="#90cdf4" icon={faClock} />
          <Box
            w={"100%"}
            alignContent={"center"}
            border={"0px solid #eaeaea"}
            borderRadius={5}
            p={1}
          >
            <Select
              value={hour}
              onChange={(e) => setHour(e.target.value)}
              fontSize={"12px"}
              h={"40px"}
            >
              <option value={"00"}>00 : 00</option>
              <option value={"01"}>01 : 00</option>
              <option value={"02"}>02 : 00</option>
              <option value={"03"}>03 : 00</option>
              <option value={"04"}>04 : 00</option>
              <option value={"05"}>05 : 00</option>
              <option value={"06"}>06 : 00</option>
              <option value={"07"}>07 : 00</option>
              <option value={"08"}>08 : 00</option>
              <option value={"09"}>09 : 00</option>
              <option value={"10"}>10 : 00</option>
              <option value={"11"}>11 : 00</option>
              <option value={"12"}>12 : 00</option>
              <option value={"13"}>13 : 00</option>
              <option value={"14"}>14 : 00</option>
              <option value={"15"}>15 : 00</option>
              <option value={"16"}>16 : 00</option>
              <option value={"17"}>17 : 00</option>
              <option value={"18"}>18 : 00</option>
              <option value={"19"}>19 : 00</option>
              <option value={"20"}>20 : 00</option>
              <option value={"21"}>21 : 00</option>
              <option value={"22"}>22 : 00</option>
              <option value={"23"}>23 : 00</option>
            </Select>
          </Box>
        </HStack>
      </HStack>
      <Textarea
        className={"small"}
        value={message}
        onChange={setOnMessageChange}
        placeholder="Wiadomość..."
      />
      <>
        <HStack w={"100%"}>
          <Button
            isLoading={loading}
            colorScheme="linkedin"
            bg={"blue.300"}
            color={"#efefef"}
            marginTop={2}
            marginBottom={4}
            w={alarmExists ? "70%" : "100%"}
            onClick={setAlarm}
          >
            Ustaw alarm
          </Button>
        </HStack>
        <Box
          w={"100%"}
          maxH={"70vh"}
          overflowY={"auto"}
          padding={4}
          bg={"#fafafa"}
          borderRadius={5}
        >
          {content}
        </Box>
      </>
    </VStack>
  );
};

export default AlarmsComponent;
