import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import DeleteNotifikation from "./DeleteNotifikation";

const NotificationItem = ({ notification }) => {
  return (
    <Box bg={"#fafafa"} p={1} borderRadius={3} m={1}>
      <HStack justify="left">
        <DeleteNotifikation nid={notification._id} />
        <Box fontSize={"12px"}>
          <Text align="start" justify="left" className="Small gray">
            {notification.createdAt}
          </Text>
          <Text
            align="start"
            justify="left"
            dangerouslySetInnerHTML={{
              __html: notification.message,
            }}
          ></Text>
        </Box>
      </HStack>
    </Box>
  );
};

export default NotificationItem;
