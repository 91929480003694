import { Box, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ModalTagsPanel from "../tags/ModalTagsPanel";
import {
  Container,
  HStack,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";

const TaskTagBadge = ({ user, chat }) => {
  const [badges, setBadges] = useState([]);
  useEffect(() => {
    const allTags = chat.tags?.map((t) => (
      <span className="badgename">#{t.name}</span>
    ));
    setBadges(allTags);
  }, [chat.tags]);
  return (
    <>
      {chat.tags.length > 0 && (
        <Popover>
          <PopoverTrigger>
            <Button size={"xs"}>{badges}</Button>
          </PopoverTrigger>

          <PopoverContent className="tag-badge-pop">
            <PopoverArrow />
            <PopoverBody p={2} alignContent={"start"} align={"left"}>
              <ModalTagsPanel user={user} chat={chat} />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </>
  );
};
export default TaskTagBadge;
