import React, { useEffect, useState } from "react";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import TagBadgeItem from "./TagBadgeItem";
import SearchTag from "./SearchTag";
import { Box, HStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setFetchAgain } from "../slices/fetchAgainSlice";

const ModalTagsPanel = ({ chat, user }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);

  const getTask = async () => {
    // if (target === "task") {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      setLoading(true);
      const { data } = await axios
        .post(
          `${mainPath}/api/chat/single/${chat._id}`,
          {
            chatId: chat._id,
            userId: user._id,
          },
          config
        )
        .then((res) => {
          const allTags = res.data.tags?.map((t) => (
            <TagBadgeItem
              user={user}
              cid={chat._id}
              tag={t}
              handleFunction={delItem}
            />
          ));
          setTags(allTags);
          // setTags(res.data.tags);
          // console.log("*********************");
          // console.log(res);
          // console.log("***************");
        });
    } catch (error) {
      console.log(error);
    }
  };
  const delItem = async (tag) => {
    setLoading(true);
    console.log("handleTags");
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .put(
          `${mainPath}/api/chat/tagdel`,
          {
            who: user._id,
            chatId: chat._id,
            tid: tag._id,
          },
          config
        )
        .then((res) => {
          dispatch(setFetchAgain(!fetchAgain));
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const allTags = chat.tags?.map((t) => (
      <TagBadgeItem
        user={user}
        cid={chat._id}
        tag={t}
        handleFunction={delItem}
      />
    ));
    setTags(allTags);
  }, [chat]);

  useEffect(() => {
    getTask();
  }, [fetchAgain]);

  return (
    <>
      {tags}
      <SearchTag title={"tagi"} user={user} chat={chat} />
    </>
  );
};

export default ModalTagsPanel;
