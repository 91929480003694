import { HStack, Checkbox } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTask } from "../slices/selectedTaskSlice";
import {
  addToSelectedTags,
  removeFromSelectedTags,
} from "../slices/selectedTagsSlice";

const TagItem = ({ tag }) => {
  const dispatch = useDispatch();
  const addTag = () => {
    dispatch(addToSelectedTags(tag));
  };
  const delTag = () => {
    dispatch(removeFromSelectedTags(tag._id));
  };
  const handleChange = (e) => {
    const checked = e.target.checked;
    checked ? addTag() : delTag();
  };
  return (
    <>
      <HStack alignContent={"start"} p={2}>
        <Checkbox onChange={handleChange} variant={"outline"}></Checkbox>
        <span>{tag.name}</span>
      </HStack>
    </>
  );
};

export default TagItem;
