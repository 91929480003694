import {
  Box,
  Button,
  Collapse,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setIsAlarmModalOpen } from "../slices/isAlarmModalOpenSlice";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { mainPath } from "../../config/AxiosConfig";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import axios from "axios";
import SingleAlarmComponent from "./SingleAlarmComponent";
import AlarmsComponent from "./AlarmsComponent";

const AlarmModal = () => {
  const selectedTask = useSelector((state) => state.selectedTask.selectedTask);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isAlarmModalOpen = useSelector(
    (state) => state.isAlarmModalOpen.isAlarmModalOpen
  );

  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(setIsAlarmModalOpen(false));
  };
  return (
    <Modal size={"md"} isOpen={isAlarmModalOpen} onClose={closeModal}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader p={"10px 5px 40px 5px"}>
          <ModalCloseButton marginTop={"5px"} bg={"#efefef"} />
        </ModalHeader>
        <ModalBody>
          <AlarmsComponent chat={selectedTask} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AlarmModal;
