import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Input,
  HStack,
  InputGroup,
  InputRightElement,
  Progress,
  VStack,
  Spinner,
  useDisclosure,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  Button,
  MenuIcon,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";

import { mainPath } from "../config/AxiosConfig";
import axios from "axios";
import AlertsRow from "../components/alerts/AlertsRow";
import { useEffect, useState } from "react";
import AlertsList from "../components/alerts/AlertsList";
import Alertpage from "./Alertpage";
import ProfileModal from "../components/miscellaneous/ProfileModal";
import { useSelector } from "react-redux";
import { ChevronDownIcon } from "@chakra-ui/icons";
const Alertspage = () => {
  const user = useSelector((state) => state.userInfo.userInfo);
  const [alert, setAlert] = useState("");
  return (
    <VStack
      w={"100%"}
      align={"right"}
      alignItems={"right"}
      alignSelf={"right"}
      alignContent={"right"}
      p={3}
      bg={"#fafafa"}
    >
      <HStack
        className={"topPanel"}
        w={"100%"}
        align={"right"}
        alignItems={"right"}
        alignSelf={"right"}
        alignContent={"right"}
        p={3}
        bg={"#fafafa"}
      >
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            <Avatar
              size="sm"
              cursor="poiner"
              name={user?.name}
              src={user?.pic}
            ></Avatar>
          </MenuButton>
          <MenuList>
            <ProfileModal user={user}>
              <MenuItem>
                <VStack alignItems={"left"} alignContent={"left"}>
                  <p align={"left"}>Mój profil:</p>
                  <p className="small gray">{user?.email}</p>
                </VStack>
              </MenuItem>
            </ProfileModal>
            <MenuDivider />
          </MenuList>
        </Menu>
      </HStack>
      {alert ? (
        <Alertpage alert={alert} setAlert={setAlert} />
      ) : (
        <AlertsList setAlert={setAlert} />
      )}
    </VStack>
  );
};

export default Alertspage;
