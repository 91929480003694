import { CloseIcon } from "@chakra-ui/icons";
import { Button, Flex, Container } from "@chakra-ui/react";
import React, { useState } from "react";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";

const TagBadgeItem = ({ tag, user, cid, handleFunction }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Button
      onClick={() => {
        setLoading(true);
        handleFunction(tag);
      }}
      isLoading={loading}
      m={1}
      size={"xs"}
    >
      {tag.name}
      <CloseIcon pl={1} />
    </Button>
  );
};

export default TagBadgeItem;
