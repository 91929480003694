import { useState, useMemo, useEffect } from "react";
import { lineString, along, distance } from "@turf/turf";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import calculateTotalDistance from "../services/calculateDistance";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import axios from "axios";
import { mainPath } from "../config/AxiosConfig";
import { Box, Button, Container, HStack, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faClose,
  faLocation,
  faMinus,
  faPlus,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import RoutesModal from "../components/routes/RoutesModal";
import { color } from "framer-motion";

const SingleRouteDetails = ({
  rej,
  start,
  end,
  baseRoute,
  alerts,
  setRoute,
}) => {
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [comparedRoute, setComparedRoute] = useState(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const [mapCenter, setMapCenter] = useState(null);
  const [mapZoom, setMapZoom] = useState(14);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [distanceStep, setDistanceStep] = useState(200);

  const dist = calculateTotalDistance(baseRoute);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const formatDate = (dateString) => {
    return format(new Date(dateString), "d MMMM yyyy, HH:mm", { locale: pl });
  };
  const calculateTimeDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Oblicz różnicę w milisekundach
    const diffMs = end - start;

    // Konwersja na poszczególne jednostki
    const minutes = Math.floor(diffMs / 60000);
    const seconds = Math.floor((diffMs % 60000) / 1000);
    const milliseconds = diffMs % 1000;

    return {
      minutes,
      seconds,
      milliseconds,
    };
  };
  const formatTimeDifference = ({ minutes, seconds, milliseconds }) => {
    return `${minutes} m ${seconds} s`;
  };
  //67dba9e82a2bdd338b33f23d
  const totalTime = calculateTimeDifference(start, end);
  const totalComparedTime = null;

  const getRoute = async ({ rid }) => {
    try {
      const { data } = await axios.get(`${mainPath}/api/route/single/${rid}`);
      setComparedRoute(data.route);
    } catch (error) {
      console.error("Błąd pobierania trasy:", error);
    }
  };
  useEffect(() => {
    getRoute({ rid: "67dba9e82a2bdd338b33f23d" });
  }, []);

  const getMarkerIcon = (markerType, alertType) => {
    if (!window.google) return null;

    const baseConfig = {
      path: window.google.maps.SymbolPath.CIRCLE,
      scale: 7,
      fillOpacity: 1,
      strokeWeight: 2,
    };

    const typeConfigs = {
      "flag-yellow": { fillColor: "#FFD700", strokeColor: "#000" },
      "flag-red": { fillColor: "#FF0000", strokeColor: "#000" },
      "flag-blue": { fillColor: "#0000FF", strokeColor: "#FFF" },
      "flag-green": { fillColor: "#008000", strokeColor: "#FFF" },
      default: { fillColor: "#888888", strokeColor: "#FFF" },
    };

    return {
      ...baseConfig,
      ...(typeConfigs[alertType] || typeConfigs.default),
    };
  };

  const generateSegments = (route) => {
    if (!route || !route.length) return [];

    return route.reduce((segments, start, i) => {
      if (i === route.length - 1) return segments;
      const end = route[i + 1];

      if (!start || !end || !start.lng || !start.lat || !end.lng || !end.lat)
        return segments;

      const line = lineString([
        [start.lng, start.lat],
        [end.lng, end.lat],
      ]);
      const d = distance(
        line.geometry.coordinates[0],
        line.geometry.coordinates[1],
        { units: "meters" }
      );
      const t = (new Date(end.timestamp) - new Date(start.timestamp)) / 1000;

      if (d === 0 || t <= 0) return segments;

      const avgSpeed = d / t;
      const numFullSegments = Math.floor(d / 10);

      for (let j = 0; j < numFullSegments; j++) {
        const startPoint = along(line, j * 10, { units: "meters" });
        const endPoint = along(line, (j + 1) * 10, { units: "meters" });
        segments.push({
          start: [
            startPoint.geometry.coordinates[1],
            startPoint.geometry.coordinates[0],
          ],
          end: [
            endPoint.geometry.coordinates[1],
            endPoint.geometry.coordinates[0],
          ],
          speed: avgSpeed,
        });
      }

      const remaining = d % 10;
      if (remaining > 0) {
        const startPoint = along(line, numFullSegments * 10, {
          units: "meters",
        });
        segments.push({
          start: [
            startPoint.geometry.coordinates[1],
            startPoint.geometry.coordinates[0],
          ],
          end: [end.lat, end.lng],
          speed: avgSpeed,
        });
      }

      return segments;
    }, []);
  };

  const getColorForSpeed = (speed) => {
    if (speed < 3) return "#FF0000"; // Czerwony (wolne)
    if (speed < 7) return "#FFFF00"; // Żółty (średnie)
    return "#00FF00"; // Zielony (szybkie)
  };
  const baseSegments = useMemo(() => generateSegments(baseRoute), [baseRoute]);
  const comparedSegments = useMemo(
    () => generateSegments(comparedRoute?.route || []),
    [comparedRoute]
  );

  const prepareChartData = (segments, step) => {
    if (!segments.length) return [];

    let chartData = [];
    let cumulativeDistance = 0;
    let cumulativeTime = 0; // Dodajemy licznik czasu
    let nextCheckpoint = step;

    segments.forEach((segment, index) => {
      const segmentDistance = distance(
        [segment.start[1], segment.start[0]],
        [segment.end[1], segment.end[0]],
        { units: "meters" }
      );

      const segmentTime = segmentDistance / segment.speed; // Czas trwania segmentu

      cumulativeDistance += segmentDistance;
      cumulativeTime += segmentTime;

      while (cumulativeDistance >= nextCheckpoint) {
        const ratio =
          (nextCheckpoint - (cumulativeDistance - segmentDistance)) /
          segmentDistance;

        const interpolatedPoint = {
          lat: segment.start[0] + ratio * (segment.end[0] - segment.start[0]),
          lng: segment.start[1] + ratio * (segment.end[1] - segment.start[1]),
        };

        const interpolatedTime =
          cumulativeTime - segmentTime + ratio * segmentTime;

        chartData.push({
          distance: nextCheckpoint,
          speed: segment.speed,
          elapsedTime: interpolatedTime, // Nowa właściwość czasu od początku
          midpoint: interpolatedPoint,
          segmentIndex: index,
        });

        nextCheckpoint += step;
      }
    });

    return chartData;
  };

  const baseChartData = useMemo(
    () => prepareChartData(baseSegments, distanceStep),
    [baseSegments, distanceStep]
  );
  const comparedChartData = useMemo(
    () => prepareChartData(comparedSegments, distanceStep),
    [comparedSegments, distanceStep]
  );

  const handleChartClick = (data) => {
    if (!data || !data.activePayload) return;

    const payload = data.activePayload[0].payload;
    setSelectedSegment(payload.segmentIndex);
    setMapCenter(payload.midpoint);
    setMapZoom(16);
  };

  return (
    <VStack verticalAlign={"start"} w={"100%"} className="wykres">
      <div
        className="map-details"
        style={{ height: "500px", width: "100%", marginBottom: 0 }}
      >
        <LoadScript
          googleMapsApiKey={API_KEY}
          onLoad={() => setScriptLoaded(true)}
        >
          {scriptLoaded && (
            <GoogleMap
              mapContainerStyle={{ height: "100%", width: "100%" }}
              center={
                mapCenter || { lat: baseRoute[0].lat, lng: baseRoute[0].lng }
              }
              zoom={mapZoom}
            >
              {/* 1️⃣ Najpierw rysujemy comparedSegments (pod spodem) */}
              {comparedSegments.map((segment, i) => (
                <Polyline
                  key={`compared-${i}`}
                  path={[
                    { lat: segment.start[0], lng: segment.start[1] },
                    { lat: segment.end[0], lng: segment.end[1] },
                  ]}
                  options={{
                    strokeColor: "#5ec7ff",
                    strokeOpacity: 0.5,
                    strokeWeight: 4,
                    zIndex: 1,
                  }}
                />
              ))}

              {/* 2️⃣ Potem rysujemy baseSegments (na wierzchu) */}
              {baseSegments.map((segment, i) => (
                <Polyline
                  key={`base-${i}`}
                  path={[
                    { lat: segment.start[0], lng: segment.start[1] },
                    { lat: segment.end[0], lng: segment.end[1] },
                  ]}
                  options={{
                    strokeColor: getColorForSpeed(segment.speed),
                    strokeOpacity: 0.9,
                    strokeWeight: 4,
                    zIndex: 2,
                  }}
                />
              ))}

              {/* Markery alertów */}
              {alerts?.map((alert) => (
                <Marker
                  key={alert._id}
                  position={{ lat: alert.latitude, lng: alert.longitude }}
                  icon={getMarkerIcon(alert.markerType, alert.alertType)}
                  onClick={() => setSelectedAlert(alert)}
                >
                  {selectedAlert?._id === alert._id && (
                    <InfoWindow onCloseClick={() => setSelectedAlert(null)}>
                      <div style={{ padding: "5px", minWidth: "200px" }}>
                        <h4 style={{ color: "#1a73e8" }}>
                          {alert.street} {alert.number}
                        </h4>
                        <p>
                          <strong>Miasto:</strong> {alert.city}
                        </p>
                        <p>
                          <strong>Kod pocztowy:</strong> {alert.postalCode}
                        </p>
                        <p>
                          <strong>Typ alertu:</strong>{" "}
                          {alert.alertType.replace(/-/g, " ")}
                        </p>
                        <p>
                          <strong>Data:</strong>{" "}
                          {new Date(alert.timestamp).toLocaleString()}
                        </p>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </GoogleMap>
          )}
        </LoadScript>
      </div>{" "}
      <HStack verticalAlign={"start"} align={"start"} p={3} w={"100%"}>
        <Button
          m={3}
          onClick={() => {
            setRoute("");
          }}
        >
          <FontAwesomeIcon className="hand" color="#aaa" icon={faClose} />
        </Button>
        <VStack minWidth={300} marginLeft={10} align={"start"} p={0}>
          <Box p={"10px 0 8px 5px"}>
            <p className="title">
              <b>TRASA GŁÓWNA</b>
            </p>
          </Box>
          <HStack
            align={"start"}
            verticalAlign={"top"}
            w={"100%"}
            className="small route-row"
          >
            <Box paddingLeft={0}>
              <FontAwesomeIcon icon={faLocation} color={"#eee"} />
            </Box>
            <VStack spacing={0} align={"start"}>
              <span className=" gray">start: </span>
              <span>
                <b>{formatDate(start)}</b>
              </span>
            </VStack>
            <Box paddingLeft={2}>
              <FontAwesomeIcon icon={faLocation} color={"#eee"} />
            </Box>
            <VStack spacing={0} align={"start"}>
              <span className=" gray">stop: </span>
              <span>
                <b>{formatDate(end)}</b>
              </span>
            </VStack>
            <Box paddingLeft={2}>
              <FontAwesomeIcon icon={faClock} color={"#eee"} />
            </Box>
            <VStack spacing={0} align={"start"}>
              <span className=" gray">czas przejazdu: </span>
              <span>
                <b>{formatTimeDifference(totalTime)}</b>
              </span>
            </VStack>
          </HStack>
          <HStack paddingLeft={6} w={"100%"} className="small route-row">
            <span className=" gray">dystans: </span>
            <span>
              <b>{dist}</b> m.
            </span>
          </HStack>
          <HStack paddingLeft={6} w={"100%"} className="small route-row">
            <span className=" gray">nr rej: </span>
            <span>
              <b>{rej}</b>
            </span>
          </HStack>
        </VStack>
        <VStack minWidth={300} marginLeft={10} align={"start"} p={0}>
          <HStack>
            <Box p={"0 0 0 5px"}>
              <p className="title">
                <b>TRASA DO PORÓWNANIA</b>
                <RoutesModal getRoute={getRoute} />
              </p>
            </Box>
          </HStack>
          <HStack
            align={"start"}
            verticalAlign={"top"}
            w={"100%"}
            className="small route-row"
          >
            <Box paddingLeft={0}>
              <FontAwesomeIcon icon={faLocation} color={"#eee"} />
            </Box>
            <VStack spacing={0} align={"start"}>
              <span className=" gray">start: </span>
              <span>
                <b>{comparedRoute && formatDate(comparedRoute?.start)}</b>
              </span>
            </VStack>
            <Box paddingLeft={2}>
              <FontAwesomeIcon icon={faLocation} color={"#eee"} />
            </Box>
            <VStack spacing={0} align={"start"}>
              <span className=" gray">stop: </span>
              <span>
                <b>{comparedRoute && formatDate(comparedRoute?.stop)}</b>
              </span>
            </VStack>
            <Box align={"start"} verticalAlign={"top"} paddingLeft={2}>
              <FontAwesomeIcon icon={faClock} color={"#eee"} />
            </Box>
            <VStack spacing={0} align={"start"}>
              <span className=" gray">czas przejazdu: </span>
              <span>
                <b>
                  {comparedRoute &&
                    formatTimeDifference(
                      calculateTimeDifference(
                        comparedRoute.start,
                        comparedRoute.stop
                      )
                    )}
                </b>
              </span>
            </VStack>
          </HStack>
          <HStack paddingLeft={6} w={"100%"} className="small route-row">
            <span className=" gray">dystans: </span>
            <span>
              <b>
                {comparedRoute && calculateTotalDistance(comparedRoute.route)}
              </b>{" "}
              m.
            </span>
          </HStack>
          <HStack paddingLeft={6} w={"100%"} className="small route-row">
            <span className=" gray">nr rej: </span>
            <span>
              <b>{comparedRoute && comparedRoute.rej}</b>
            </span>
          </HStack>
        </VStack>
      </HStack>
      <div
        style={{
          overflowX: "scroll",
          background: "#fff",
          maxWidth: "100vw",
          padding: "0px 50px",
        }}
      >
        <LineChart
          width={Math.max(1800, baseChartData.length * 6)}
          height={200}
          onClick={handleChartClick}
          data={baseChartData}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="distance"
            label={{
              style: { fontWeight: "bold", fontSize: "14px", fill: "#555" },
              value: "Dystans (m)",
              position: "top",
            }}
            tick={{ fontSize: 12, fill: "#555" }}
            stroke="#eee"
          />
          <YAxis
            label={{
              style: { fontWeight: "bold", fontSize: "12px", fill: "#555" },
              value: "Prędkość (m/s)",
              angle: -90,
              position: "insideLeft",
            }}
            domain={[0, "auto"]}
            tick={{ fontSize: 12, fill: "#555" }}
            stroke="#eee"
          />
          <Tooltip
            formatter={(value, name, props) => {
              if (name === "Trasa wzorcowa" || name === "Aktualna trasa") {
                return [`${value.toFixed(2)} m/s`, "Prędkość"];
              }
              return [
                props.payload.elapsedTime.toFixed(1) + " s",
                "Czas od startu",
              ];
            }}
            labelFormatter={(label, payload) =>
              payload.length
                ? `Czas: ${payload[0].payload.elapsedTime.toFixed(1)} s`
                : ""
            }
          />

          <Line
            type="monotone"
            dataKey="speed"
            data={baseChartData}
            stroke="#00FF00"
            name="Trasa wzorcowa"
          />
          <Line
            type="monotone"
            dataKey="speed"
            data={comparedChartData}
            stroke="#888888"
            name="Aktualna trasa"
            strokeDasharray="5 5"
          />
        </LineChart>
      </div>
      <Box
        w={"60vw"}
        borderRadius={5}
        bg={"#fff"}
        align={"center"}
        style={{
          textAlign: "center",
          padding: "50px",
          margin: "30 auto",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <Button
          size={"sm"}
          borderRadius={5}
          bg={"blue.200"}
          onClick={() => setDistanceStep((prev) => Math.max(prev - 10, 10))}
        >
          <FontAwesomeIcon className="hand" color="white" icon={faMinus} />
        </Button>
        <input
          type="range"
          min="10"
          max="1000"
          step="10"
          value={distanceStep}
          onChange={(e) => setDistanceStep(Number(e.target.value))}
          style={{
            borderRadius: "5px",
            border: "none",
            outline: "none",
            width: "90vw",
            margin: "0 auto",
            WebkitAppearance: "none",
            MozAppearance: "none",
            // Dodajemy zmienną CSS do kontroli wypełnienia
            "--fill-percentage": `${
              ((distanceStep - 10) / (1000 - 10)) * 100
            }%`,
          }}
        />
        <Button
          size={"sm"}
          borderRadius={5}
          bg={"blue.200"}
          onClick={() => setDistanceStep((prev) => Math.min(prev + 10, 1000))}
        >
          <FontAwesomeIcon className="hand" color="white" icon={faPlus} />
        </Button>
        <span>{distanceStep}m</span>
      </Box>
    </VStack>
  );
};

export default SingleRouteDetails;
