import {
  Button,
  HStack,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faClose } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import NotificationItem from "./NotificationItem";
const NotificationsModal = ({ notifications }) => {
  const [content, setContent] = useState(<></>);
  useEffect(() => {
    const not = notifications.map((n) => <NotificationItem notification={n} />);
    setContent(not);
  }, [notifications]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Popover closeOnBlur={false} isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          onClick={onOpen}
          bg={"transparent"}
          size={"md"}
          color={"blue.300"}
        >
          <HStack>
            <p color={"#aaaaaa"} className="small">
              {notifications.length}
            </p>
            <FontAwesomeIcon marginRight={"10px"} icon={faBell} />
          </HStack>
        </Button>
      </PopoverTrigger>
      <PopoverContent marginRight={5}>
        <PopoverHeader h={"40px"}>
          <PopoverCloseButton size={"md"}>
            <FontAwesomeIcon color="#aaa" icon={faClose} />
          </PopoverCloseButton>
        </PopoverHeader>
        <PopoverArrow />
        <PopoverBody p={0}>{content}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
export default NotificationsModal;
