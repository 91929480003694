// import { Button } from '@chakra-ui/react';
import { Route, Switch } from "react-router-dom";
import Chatpage from "../src/Pages/Chatpage";
import Trackingpage from "../src/Pages/Trackingpage";
import Resetpage from "../src/Pages/Resetpage";
import Homepage from "../src/Pages/Homepage";
import Calendarpage from "../src/Pages/Calendarpage";
import "./App.css";
import { Slider } from "@chakra-ui/react";
import Routespage from "./Pages/Routespage";
import Routepage from "./Pages/Routepage";
import Alertspage from "./Pages/Alertspage";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/chats/:sort?/:asc?" component={Chatpage} />
        <Route exact path="/tracking/:sort?/:asc?" component={Trackingpage} />
        <Route exact path="/alerts" component={Alertspage} />
        <Route exact path="/routes" component={Routespage} />
        <Route exact path="/route/:rid?" component={Routepage} />
        <Route exact path="/calendar/:task?" component={Calendarpage} />
        <Route exact path="/reset/:token?" component={Resetpage} />
      </Switch>
    </div>
  );
}

export default App;
