import {
  Box,
  Button,
  Collapse,
  HStack,
  Container,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { ContextScope } from "../../Context/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { format, set } from "date-fns";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { setModalMode } from "../slices/modalModeSlice";
import { setIsUpdateChatModalOpen } from "../slices/isUpdateChatModalOpenSlice";
import { useEffect, useState } from "react";
import DoneTaskComponent from "../task/DoneTaskComponent";
import axios from "axios";
import { setIsChatDrawerOpen } from "../isChatDrawerOpenSlice";
import { mainPath } from "../../config/AxiosConfig";
import { setFetchAgain } from "../slices/fetchAgainSlice";
import { setSelectedTask } from "../slices/selectedTaskSlice";
import TeamList from "../task/TeamList";
import { setDrawerMode } from "../slices/drawerModeSlice";
import { setIsAlarmModalOpen } from "../slices/isAlarmModalOpenSlice";
import { setSelectedAlarm } from "../slices/selectedAlarmSlice";
import alarm from "../../images/alarm.png";
import alarmon from "../../images/frontalarmon.png";

const Subtask = ({
  color,
  subtask,
  isOpen,
  index,
  last,
  chaContainerone,
  opened,
}) => {
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const {
    alert,
    setAlert,
    selectedChat,
    setSelectedChat,
    setSelectedPrivateChat,
    chats,
    setChats,
  } = ContextScope();
  const drawerMode = useSelector((state) => state.drawerMode.drawerMode);
  const user = useSelector((state) => state.userInfo.userInfo);
  const [done, seContainerone] = useState(false);
  const [showCollapse, setShowCollapse] = useState(isOpen);
  const [daysLeftBG, seContaineraysLeftBG] = useState("gray.300");
  const [myClass, setMyClass] = useState("subtaskTr");
  const [isAdmin, setIsAdmin] = useState(false);
  const [showDescription, setShowDescription] = useState(true);
  const [showDoneClass, setShowDoneClass] = useState("subtaskTr");
  const [descriptionCollapse, seContainerescriptionCollapse] = useState(false);
  const [myLastClass, setMyLastClass] = useState("subtaskTr");
  const [daysLeftColor, seContaineraysLeftColor] = useState("gray.800");
  const dispatch = useDispatch();
  const selectedTask = useSelector((state) => state.selectedTask.selectedTask);
  const timeLeft = Math.round(
    (new Date(subtask.dateTo) - Date.now()) / (1000 * 60 * 60 * 24)
  );
  const showDone = useSelector((state) => state.showDone.showDone);
  //   useEffect(() => {
  //     if (subtask?.groupAdmin) {
  //       if (subtask?.groupAdmin?.some((e) => e._id === user._id)) {
  //         setIsAdmin(true);
  //       } else {
  //         setIsAdmin(false);
  //       }
  //     }
  //     if (timeLeft < 10 && timeLeft > 0) {
  //       seContaineraysLeftBG("#ff9326");
  //       seContaineraysLeftColor("white");
  //     } else if (timeLeft <= 0) {
  //       seContaineraysLeftBG("red");
  //       seContaineraysLeftColor("white");
  //     } else {
  //       seContaineraysLeftBG("gray.300");
  //       seContaineraysLeftColor("gray.800");
  //     }
  //     // if (isOpen === true) {
  //     //   if (subtask.done === true) {
  //     //     if (showDone === false) {
  //     //       setShowCollapse(false);
  //     //     } else setShowCollapse(true);
  //     //     seContaineraysLeftBG("#9fba3c");
  //     //     seContaineraysLeftColor("white");
  //     //   } else {
  //     //     if (isOpen === true) setShowCollapse(true);
  //     //   }

  //     //   if (index === 0) {
  //     //     setMyClass("subtaskTrFirstTop");
  //     //     setMyLastClass("subtaskTrLastTop");
  //     //   }
  //     //   if (index !== 0) {
  //     //     setMyClass("subtaskTr");
  //     //     setMyLastClass("subtaskTr");
  //     //   }
  //     //   if (index === last) {
  //     //     setMyClass("subtaskTrFirstBottom");
  //     //     setMyLastClass("subtaskTrLastBottom");
  //     //   }
  //     //   if (index === 0 && last === 0) {
  //     //     setMyClass("subtaskTrFirstAll");
  //     //     setMyLastClass("subtaskTrLastAll");
  //     //   }
  //     // } else setShowCollapse(false);
  //   }, [subtask]);
  const openDrawer = (subtask) => {
    // // // console.log(isChaContainerrawerOpen);
    setSelectedChat(subtask);
    dispatch(setIsChatDrawerOpen(true));
  };
  const openModal = () => {
    dispatch(setModalMode("sub"));
    dispatch(setIsUpdateChatModalOpen(true));
    setSelectedChat(subtask);
  };
  const toast = useToast();
  // useEffect(() => {
  //   seContainerone(subtask.done);
  // }, [subtask]);
  const handleSubClick = async (target) => {
    // // console.log(`subtask`);
    // // console.log(subtask);
    // // console.log(selectedChat);
    // // console.log(selectedTask);
    // // console.log(`subtask`);
    setSelectedPrivateChat(null);
    setSelectedChat(subtask);
    // dispatch(setSelectedTask(subtask));
    dispatch(setDrawerMode(target));
    openDrawer(subtask);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const { data } = await axios.put(
        `${mainPath}/api/chat/read`,
        {
          chatId: subtask?._id,
        },
        config
      );

      dispatch(setFetchAgain(!fetchAgain));
    } catch (error) {}
  };
  const dateTo = format(new Date(subtask?.dateTo.toString()), "dd/MM/yyyy");

  const handleAlarm = () => {
    dispatch(setSelectedTask(subtask));
    dispatch(setIsAlarmModalOpen(true));
    dispatch(setSelectedAlarm(subtask.alarm));
  };
  // // // console.log(subtask);
  useEffect(() => {
    // if (showDone === false) {
    //   setMyClass("subtaskTr");
    //   setMyLastClass("subtaskTr");
    //   setShowDoneClass("subtaskTr");
    // }
    // // // console.log(
    //   `subtask - showDone: ${showDone}, subtask.done: ${subtask.done}`,
    // );

    if (showDone === false && chaContainerone === true) {
      //   setShowCollapse(false);
    } else {
      if (showDone === false && subtask.done === true) {
        // setShowCollapse(false);
      } else {
        // if (opened === true) setShowCollapse(true);
      }
    }
  }, [showDone]);
  return (
    <HStack w={"100%"} bg={color} borderRadius={5} m={"10px 0px"}>
      <VStack>
        <Container>
          <HStack p={"15px 3px 0px 15px"}>
            {subtask.groupAdmin.some((e) => e === user._id).toString() ? (
              <DoneTaskComponent mode="sub" chat={subtask} />
            ) : (
              <Button
                isDisabled={true}
                bg={subtask.done ? "#9fba3c" : "#eaeaea"}
                size={"xs"}
                display={{ base: "flex" }}
              >
                {subtask.done ? (
                  <FontAwesomeIcon color="#ffffff" icon={faCheck} />
                ) : (
                  <FontAwesomeIcon color="#ffffff" icon={faCheck} />
                )}
              </Button>
            )}
            <>
              {(subtask.groupAdmin.some((e) => e._id === user._id) ||
                subtask.groupSuper._id.toString() === user._id.toString()) && (
                <Button
                  onClick={openModal}
                  bg={"#fafafa"}
                  size={"xs"}
                  display={{ base: "flex" }}
                >
                  <FontAwesomeIcon color="#aaaaaa" icon={faGear} />
                </Button>
              )}
            </>
            <Button
              bg={"#fafafa"}
              size={"xs"}
              color="#05cdff"
              onClick={() => {
                handleSubClick("notes");
              }}
            >
              <FontAwesomeIcon color="#de924b" icon={faPenToSquare} />
            </Button>
            <Button
              bg={subtask.newPrivateMessages > 0 ? "#fadede" : "#fafafa"}
              p={"1px 21px 1px 15px"}
              size={"xs"}
              color="#05cdff"
              onClick={() => {
                handleSubClick("chat");
              }}
              leftIcon={<FontAwesomeIcon color="#4bb2de" icon={faComment} />}
            >
              <HStack>
                <span padding={"0px 5px"} className="small lightgray">
                  {subtask.allPrivateMessages}
                </span>
                {subtask.newPrivateMessages > 0 && (
                  <text padding={"0px 5px"} className="small red">
                    {subtask.newPrivateMessages}
                  </text>
                )}
              </HStack>
            </Button>
            <Button
              bg={subtask.newMessages > 0 ? "#fadede" : "#fafafa"}
              p={"1px 21px 1px 15px"}
              size={"xs"}
              color="#05cdff"
              onClick={() => {
                handleSubClick("chats");
              }}
              leftIcon={<FontAwesomeIcon color="#4bb2de" icon={faComments} />}
            >
              <HStack>
                <span padding={"0px 5px"} className="small lightgray">
                  {subtask.allMessages}
                </span>
                {subtask.newMessages > 0 && (
                  <text padding={"0px 5px"} className="small red">
                    {subtask.newMessages}
                  </text>
                )}
              </HStack>
            </Button>
            <HStack className="small gray">
              <Button width={"40px"} onClick={handleAlarm} size={"xs"}>
                {subtask?.alarm?.length > 0 ? (
                  <img alt="set alarm" src={alarmon} />
                ) : (
                  <img
                    className="alarmimg"
                    width={"15px"}
                    height={"15px"}
                    src={alarm}
                    alt="set alarm"
                  />
                )}
              </Button>
              <Text>{dateTo}</Text>
              <Box
                borderRadius={5}
                color={daysLeftColor}
                bg={daysLeftBG}
                p={"3px 7px 2px 5px"}
              >
                {subtask.done === false ? (
                  `${timeLeft} dni`
                ) : (
                  <Box p={"0px 4px 0px 4px"}>
                    <FontAwesomeIcon color="#ffffff" icon={faCheck} />
                  </Box>
                )}
              </Box>
            </HStack>
          </HStack>
        </Container>
        {/* <Container
          onClick={() => seContainerescriptionCollapse(!descriptionCollapse)}
          bg={"blue.100"}
          className={showDoneClass}
        >
        </Container> */}
        {/* <Container bg={"blue.100"} className={showDoneClass}>
          <Collapse in={showCollapse} animateOpacity>
          </Collapse>
        </Container> */}
        <HStack
          w={"100%"}
          align={"start"}
          alignContent={"start"}
          alignItems={"start"}
          alignSelf={"start"}
        >
          <HStack>
            <VStack
              w={"100%"}
              align={"start"}
              alignContent={"start"}
              alignItems={"start"}
              alignSelf={"start"}
            >
              <HStack>
                <Text
                  paddingLeft={"30px"}
                  className="smaller"
                  h={"10%"}
                  verticalAlign={"top"}
                  color={
                    subtask.groupAdmin.some((e) => e === user._id)
                      ? "blue.600"
                      : "gray.400"
                  }
                >
                  {format(
                    new Date(subtask?.createdAt.toString()),
                    "dd/MM/yyyy"
                  )}
                </Text>
                <Text
                  marginLeft={"30px"}
                  className="smaller"
                  h={"10%"}
                  verticalAlign={"top"}
                  color={
                    subtask.groupAdmin.some((e) => e === user._id)
                      ? "blue.600"
                      : "gray.400"
                  }
                >
                  {subtask.groupAdmin[0].name}
                </Text>
              </HStack>
              <Text
                onClick={() => {
                  handleSubClick("notes");
                }}
                className={subtask.done ? "doneTitle" : "title hand"}
                padding={"0px 3px 10px 30px"}
              >
                <p className={subtask.description && "link hand"}>
                  {subtask.chatName}
                </p>
              </Text>
              <Collapse in={showDescription} animateOpacity>
                {showDescription && subtask?.description && (
                  <Box
                    borderRadius={6}
                    w={"100%"}
                    p={2}
                    m={"0px 20px 20px 20px"}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: subtask?.description,
                      }}
                    ></p>
                  </Box>
                )}
              </Collapse>
            </VStack>
          </HStack>
        </HStack>
      </VStack>
    </HStack>
  );
};
export default Subtask;
