import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { mainPath } from "../../config/AxiosConfig";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Spinner,
  PopoverHeader,
  HStack,
  Popover,
  PopoverBody,
} from "@chakra-ui/react";
import NotificationsPopup from "./NotificationsPopup";
import { useSelector } from "react-redux";
const NotificationsComponent = ({ user }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState([]);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const getNotifications = async () => {
    setLoading(true);
    // console.log("====================getAlarm");
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .get(`${mainPath}/api/notifications/${user?._id}`, config)
        .then((res) => {
          setNotifications(res.data);
          // console.log("res==========================");
          // console.log(res);
          // console.log("====res==============");
          setLoading(false);
        });
    } catch (error) {
      // console.log("err==========================");
      // console.log(error);
      // console.log("====err==============");
    }
  };
  useEffect(() => {
    if (user?._id != null) {
      getNotifications();
    }
  }, [user, fetchAgain]);
  // useEffect(() => {
  //   if (notifications.length > 0) {
  //     setContent();
  //   }
  // }, [notifications]);
  return (
    <>
      <HStack>
        {loading && (
          <div width="100%" align="center">
            <Spinner
              size="sm"
              color="white"
              alignSelf={"center"}
              alignContent={"center"}
            />
          </div>
        )}
        <>
          <NotificationsPopup notifications={notifications} />
        </>
      </HStack>
    </>
  );
};
export default NotificationsComponent;
