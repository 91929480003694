import { Button } from "@chakra-ui/react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { useState } from "react";
import { setFetchAgain } from "../slices/fetchAgainSlice";

const DeleteNotifikation = ({ nid }) => {
  const [loading, setLoading] = useState(false);
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userInfo.userInfo);
  const delNot = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .post(
          `${mainPath}/api/notifications/del/${nid}`,
          {
            uid: user._id,
          },
          config
        )
        .then((res) => {
          dispatch(setFetchAgain(!fetchAgain));
          setLoading(false);
        });
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };
  return (
    <Button
      onClick={delNot}
      isLoading={loading}
      color={"red"}
      size={"sm"}
      bg={"transparent"}
    >
      <FontAwesomeIcon icon={faClose} />
    </Button>
  );
};

export default DeleteNotifikation;
