import { Box, Button } from "@chakra-ui/react";
import axios from "axios";
import { mainPath } from "../../config/AxiosConfig";
import { useDispatch, useSelector } from "react-redux";
import { setFetchAgain } from "../slices/fetchAgainSlice";

const ModalTagsListItem = ({ tag, user, chat }) => {
  const fetchAgain = useSelector((state) => state.fetchAgain.fetchAgain);
  const dispatch = useDispatch();
  const handleTags = async ({ tagToAdd }) => {
    console.log("handleTags");
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios
        .put(
          `${mainPath}/api/chat/tagadd`,
          {
            who: user._id,
            chatId: chat?._id,
            tid: tag._id,
          },
          config
        )
        .then((res) => {
          dispatch(setFetchAgain(!fetchAgain));
          console.log(res);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return <Button onClick={handleTags}>{tag.name}</Button>;
};
export default ModalTagsListItem;
