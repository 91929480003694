import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Input,
  HStack,
  InputGroup,
  InputRightElement,
  Progress,
  VStack,
  Spinner,
  useDisclosure,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";

import { mainPath } from "../../config/AxiosConfig";
import axios from "axios";
import RoutesRow from "../../components/routes/RoutesRow";
import { useEffect, useState } from "react";
const RoutesList = ({ setRoute, routes }) => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(<></>);
  useEffect(() => {
    setContent(
      routes?.map((route) => <RoutesRow route={route} setRoute={setRoute} />)
    );
  }, [routes]);
  return (
    <Box
      className="mainbox"
      justifyContent="space-between"
      w="100%"
      h="91.5vh"
      p="10px"
    >
      {loading ? (
        <Progress height={"2px"} w={"100%"} size="xs" isIndeterminate />
      ) : (
        <Box
          p={0}
          w={"100%"}
          borderRadius="10px"
          className="tableBox"
          border={"1px solid #eee"}
          maxW={"98vw"}
          maxH={"80vh"}
          overflowX={"auto"}
          overflowY={"auto"}
        >
          <Table w="100%" h="100%">
            <Thead className="stickyHeader">
              <Tr bg={"white"}>
                <Th></Th>
                <Th>nr rejestracyjny</Th>
                <Th>czas trwania</Th>
                <Th>dystans</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody
              w="100%"
              align={"center"}
              alignContent={"center"}
              alignItems={"center"}
              alignSelf={"center"}
              justifyContent={"center"}
            >
              {content}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default RoutesList;
