import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import axios from "axios";
import { mainPath } from "../config/AxiosConfig";
import LiveVehiclesModule from "../components/trackingLive/LiveVehiclesModule";

const mapContainerStyle = {
  width: '100%',
  height: '100vh',
};

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const LiveMap = () => { 
  const [positions, setPositions] = useState([]);

  const [mapCenter, setMapCenter] = useState({ 
    lat: 52.4093675, 
    lng: 16.9017741 
  });
  const [mapInstance, setMapInstance] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const handleVehicleClick = useCallback((position) => {
    if(position && position.latitude && position.longitude) {
      const newCenter = {
        lat: position.latitude,
        lng: position.longitude
      };
      
      setMapCenter(newCenter);
      
      if(mapInstance) {
        mapInstance.panTo(newCenter);
      }
    }
  }, [mapInstance]);
  
  useEffect(() => {
    const socket = io('https://notatka-backend.onrender.com', {
      withCredentials: true,
    });

    // Dołącz do pokoju trackingu
    socket.emit('join tracking room', 'tracking');

    // Nasłuchuj aktualnych pozycji Z SOCKETA (bez zapytań HTTP!)
    socket.on('new_position', (updatedPosition) => {
      console.log('np');
      console.log(updatedPosition);
      console.log('np');
      setPositions(prev => {
        const updated = prev.filter(p => p.id !== updatedPosition.id);
        return [...updated, updatedPosition];
      });
    });

    socket.on('position_removed', ({ id }) => {
      setPositions(prev => prev.filter(position => position.id !== id));
    });

    // Pobierz początkowe pozycje (tylko raz!)
    const fetchInitialPositions = async () => {
      try {
        const { data } = await axios.get(`${mainPath}/api/route/live`);
        setPositions(data);
      } catch (error) {
        console.error('Błąd pobierania pozycji:', error);
      }
    };
    fetchInitialPositions();

    return () => {
      socket.disconnect();
    };
  }, []);

  // ✅ Nowy useEffect do usuwania nieaktywnych użytkowników
  useEffect(() => {
    const checkInactiveUsers = () => {
      setPositions(prev => {
        const now = Date.now();
        const timeout = 5 * 60 * 1000; // 5 minut

        return prev.filter(pos => {
          const lastUpdate = new Date(pos.timestamp).getTime();
          return now - lastUpdate < timeout; // Usuń tylko stare pozycje
        });
      });
    };

    // Uruchamiaj sprawdzanie co minutę
    const interval = setInterval(checkInactiveUsers, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div style={{ width: "20vw" }}>
        <LiveVehiclesModule onVehicleClick={handleVehicleClick} />
      </div>
      <div style={{ width: "80vw" }}>
        <LoadScript googleMapsApiKey={API_KEY} libraries={["places"]}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={14}
            center={mapCenter}
            onLoad={map => setMapInstance(map)}
          >  
            {positions.map((pos) => (
              <Marker
                key={pos.id}
                position={{ lat: pos.latitude, lng: pos.longitude }}
                label={pos.rej}
              />
            ))} 
 
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default LiveMap;
