import { Button, HStack, Td, Tr, VStack } from "@chakra-ui/react";
import calculateTotalDistance from "../../services/calculateDistance";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DelRouteComponent from "./DelRouteComponent";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const RoutesRow = ({ route, setRoute }) => {
  // console.log(route);
  const distance = calculateTotalDistance(route.route);

  const start = route.start;
  const end = route.stop;

  const formatDate = (dateString) => {
    return format(new Date(dateString), "d MMMM yyyy, HH:mm", { locale: pl });
  };
  const calculateTimeDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Oblicz różnicę w milisekundach
    const diffMs = end - start;

    // Konwersja na poszczególne jednostki
    const minutes = Math.floor(diffMs / 60000);
    const seconds = Math.floor((diffMs % 60000) / 1000);
    const milliseconds = diffMs % 1000;

    return {
      minutes,
      seconds,
      milliseconds,
    };
  };
  const formatTimeDifference = ({ minutes, seconds, milliseconds }) => {
    return `${minutes} m ${seconds} s`;
  };
  const totalTime = calculateTimeDifference(start, end);
  return (
    <>
      <Tr className="alerts-row">
        <Td>
          <Link
            to={`../route/${route._id}`}
            target="_parent"
            rel="noopener noreferer"
          >
            <Button size={"sm"} color={"white"} bg={"blue.200"}>
              <FontAwesomeIcon
                size="bg"
                color="white"
                icon={faMagnifyingGlass}
              />
            </Button>
          </Link>
        </Td>
        <Td align="left">
          <p
            onClick={() => {
              setRoute(route);
            }}
            className="title hand"
          >
            {route.rej}
          </p>
          {/* <p className="gray">{route._id}</p> */}
        </Td>
        <Td align="left">
          <VStack align="left">
            <HStack>
              <span className="small gray">start: </span>
              <span>{formatDate(route.start)}</span>
            </HStack>
            <HStack>
              <span className="small gray">stop: </span>
              <span>{formatDate(route.stop)}</span>
            </HStack>
            <HStack>
              <span className="small gray">czas trwania:</span>{" "}
              <b>{formatTimeDifference(totalTime)}</b>
            </HStack>
          </VStack>
        </Td>
        <Td>{distance} m</Td>
        <Td>
          <DelRouteComponent rid={route._id} />
        </Td>
      </Tr>
    </>
  );
};

export default RoutesRow;
